import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

import { environment } from '../../environments/environment';
import { BaseResponse } from '../models';

@Injectable()
export abstract class BaseService {
  constructor(protected http: HttpClient) {}

  protected get<T>(path: string, params?: HttpParams) {
    return this.http
      .get<BaseResponse<T>>(`${environment.apiUrl}${path}`, { params })
      .pipe(map((result) => result.data));
  }

  protected getBlob(path: string, params?: HttpParams) {
    return this.http
      .get(`${environment.apiUrl}${path}`, { params, responseType: 'blob' })
      .pipe(map((result) => result));
  }

  protected put<T>(path: string, body?: any) {
    return this.http
      .put<BaseResponse<T>>(`${environment.apiUrl}${path}`, body)
      .pipe(map((result) => result.data));
  }

  protected post<T>(path: string, body?: any, headers?: any) {
    return this.http
      .post<BaseResponse<T>>(`${environment.apiUrl}${path}`, body, {headers})
      .pipe(map((result) => result.data));
  }

  protected patch<T>(path: string, body?: any) {
    return this.http
      .patch<BaseResponse<T>>(`${environment.apiUrl}${path}`, body)
      .pipe(map((result) => result.data));
  }

  protected delete<T>(path: string) {
    return this.http
      .delete<BaseResponse<T>>(`${environment.apiUrl}${path}`)
      .pipe(map((result) => result.data));
  }
}
