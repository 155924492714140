import {
  Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input,
} from '@angular/core';
import { DatePickerType } from '@enums';
import * as dayjs from 'dayjs';

@Component({
  selector: 'date-filter',
  templateUrl: './date-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFilterComponent implements OnInit {
  @Input()
    placeholder: string;

  @Input() defaultType: DatePickerType;

  @Input() defaultDates: Date[];

  @Input() hideTypes: DatePickerType[] = [];

  @Output() change = new EventEmitter<{filter:string | string[],type:DatePickerType}>();

  dropdownOpen = false;

  DatePickerType = DatePickerType;

  type: DatePickerType | undefined = DatePickerType.DATE;

  date?: Date;

  range?: Date[];

  currentValue?: string;

  constructor() { }

  ngOnInit(): void {
    if (this.defaultDates && this.defaultDates.length > 1) {
      this.range = this.defaultDates;
      this.onRangeChange()
    } else if (this.defaultDates && this.defaultDates.length > 0) {
      this.date = this.defaultDates[0];
      this.onCustomDateChange();
    }
    if (this.defaultType && Object.values(DatePickerType).includes(this.defaultType)) this.type = this.defaultType;
    this.onApplyFilter();
  }

  onCustomDateChange() {
    this.type = DatePickerType.DATE;
  }

  onRangeChange() {
    this.type = DatePickerType.RANGE;
  }

  removeFilter() {
    this.date = undefined;
    this.range = undefined;
    this.type = undefined;
    this.onApplyFilter();
  }

  onApplyFilter() {
    this.currentValue = undefined;

    switch (this.type) {
      default: this.change.emit(undefined); break;
      case DatePickerType.TODAY: {
        const startDate = dayjs().startOf('day').toISOString();
        const endDate = dayjs().endOf('day').toISOString();
        this.change.emit({filter:[startDate, endDate],type:this.type});
        this.currentValue ='Today';
        break;
      }
      case DatePickerType.WEEK: {
        //adding one day to make week range from monday to sunday
        let startDate=dayjs().startOf('week').add(1,'day').startOf('day').toISOString();
        let endDate= dayjs().endOf('week').add(1,'day').endOf('day').toISOString();
        if(dayjs().day()===dayjs().startOf('week').day()){
          startDate=dayjs().startOf('week').add(1,'day').subtract(1,'week').startOf('day').toISOString();
          endDate= dayjs().endOf('week').add(1,'day').subtract(1,'week').endOf('day').toISOString();
        }
        this.change.emit({filter:[startDate, endDate],type:this.type});
        this.currentValue = 'This Week';
        break;
      }
      case DatePickerType.MONTH: {
        const startDate = dayjs().startOf('month').startOf('day').toISOString();
        const endDate = dayjs().endOf('month').endOf('day').toISOString();
        this.change.emit({filter:[startDate, endDate],type:this.type});
        this.currentValue = 'This Month';
        break;
      }
      case DatePickerType.PREVIOUS_MONTH: {
        const startDate = dayjs().subtract(1, 'month').startOf('month').startOf('day').toISOString();
        const endDate = dayjs().subtract(1, 'month').endOf('month').endOf('day').toISOString();
        this.change.emit({filter:[startDate, endDate],type:this.type});
        this.currentValue = 'Previous Month';
        break;
      }
      case DatePickerType.DATE:
        if (!this.date) {
          this.change.emit(undefined);
          break;
        }

        this.change.emit({filter:dayjs(this.date).startOf('day').toISOString(),type:this.type});

        if (this.date) {
          this.currentValue = this.date.toLocaleDateString();
        }
        break;

      case DatePickerType.RANGE: {
        if (!this.range) {
          this.change.emit(undefined);
          break;
        }

        const [start, end] = this.range;

        const startDate = dayjs(start).startOf('day').toISOString();
        const endDate = dayjs(end).endOf('day').toISOString();

        this.change.emit({filter:[startDate, endDate],type:this.type});

        if (this.range) {
          this.currentValue = `${this.range[0].toLocaleDateString()} - ${this.range[1].toLocaleDateString()}`;
        }
        break;
      }
    }

    this.dropdownOpen = false;
  }
}
