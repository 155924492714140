import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HelpVideoCatalogue } from '@models/help.interface';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { BaseService } from './base.service.ts';

@Injectable({ providedIn: 'root' })
export class HelpService extends BaseService {
  helpVideoCatalogue$=new BehaviorSubject<HelpVideoCatalogue[]>([]);

  currentRoute$:BehaviorSubject<string>;

  constructor(http: HttpClient,private router:Router) {
    super(http);
      this.currentRoute$=new BehaviorSubject<string>(this.router.url);
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.currentRoute$.next(event.url);
        };
      });
  }

  getHelpVideos(){
    return this.get<HelpVideoCatalogue[]>('/help');
  }

  setHelpVideoCatalogue(data:HelpVideoCatalogue[]){
    this.helpVideoCatalogue$.next(data);
  }
}
