import { HttpParams } from '@angular/common/http';
import { TransactionAction } from '@enums';
import { Merchant } from '@models';
import { TransactionsSummary, BatchSummaryProcessed } from '@models/batch-summary';

/**
 * Convert any object properties into valid query parameters
 * @param query
 */
export const createHttpQueryParams = <T extends {}>(query: T) => {
  let params = new HttpParams();

  Object.keys(query).forEach((key) => {
    const value = query[key as keyof T];
    const values = Array.isArray(value) ? value : [value];

    values.forEach((v) => {
      if (v !== null && v !== undefined) {
        params = params.append(key, v);
      }
    });
  });

  return params;
};

/**
 * Convert a string amount or a float dollar amount to cents value in integer
 * @param {string | number} value
 * @returns {number}
 * Example:
 * (9.22) => 922
 * ('9.22') => 922
 */

export const formatDollarsToCents = (value: string | number): number => {
  value = (`${value}`).replace(/[^\d.-]/g, '');
  if (value && value.includes('.')) {
    value = value.substring(0, value.indexOf('.') + 3);
  }
  return value ? Math.round(parseFloat(value) * 100) : 0;
};

/**
 * Convert a string or an integer cent amount to dollar amount in float
 * @param {string | number} value
 * @returns {number}
 * Example:
 * ('922') => 9.22
 * (922) => 9.22
 */
export const formatCentsToDollars = (value: string | number): number => {
  value = (`${value}`).replace(/[^\d.-]/g, '');
  value = parseFloat(value);
  return value ? value / 100 : 0;
};

/**
 * returns remainder
 * @param num 
 * @param divisior 
 * @returns {number}
 * Example:
 * (9,1.8) => 0
 * (5,2) => 1
 */
export const getRemainder = (num: number, divisior: number): number => {
  return num - divisior * Math.floor(num / divisior);
}

export const isLocalStorageAvailable = (): boolean => {
    const test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}

export const formatBatchSummary = (summary: TransactionsSummary,merchants?:Merchant[]): BatchSummaryProcessed => {
  const cardTypeInitValue = {
    totalTransaction: 0,
    totalAmount: 0,
    surcharge:0,
    subtotal:0,
  };
  const processedSummary: BatchSummaryProcessed = {
    total: 0,
    subtotal:0,
    surcharge:0,
    cardDetails: [],
    isSubtotalAvailable:false,
    [TransactionAction.Sale]: JSON.parse(JSON.stringify(cardTypeInitValue)),
    [TransactionAction.Refund]: JSON.parse(JSON.stringify(cardTypeInitValue)),
  };
  return summary.cardDetails.reduce((processedData, cardDetail) => {
    const saleItem = cardDetail.transactionDetails.find((item) => item.type === TransactionAction.Sale);
    const refundItem = cardDetail.transactionDetails.find((item) => item.type === TransactionAction.Refund);
    const saleAmount = saleItem ? Number(saleItem?.amount.split("$")[1]) : 0;
    const saleSubtotalAmount = saleItem ? Number(saleItem?.subtotal?.split("$")[1] || 0) : 0;
    const saleSurchargeAmount = saleItem ? Number(saleItem?.surcharge?.split("$")[1] || 0) : 0;
    const refundAmount = refundItem ? (Number(refundItem?.amount.split("$")[1]) * -1) : 0;
    const refundSubtotalAmount = refundItem ? (Number(refundItem?.subtotal?.split("$")[1]|| 0) * -1) : 0;
    const refundSurchargeAmount = refundItem ? (Number(refundItem?.surcharge?.split("$")[1] || 0)* -1) : 0;
   processedData.isSubtotalAvailable=saleItem?.subtotal? true :false;
    const res = {
      cardType: cardDetail.cardType,
      merchantName:merchants?merchants.find(item=>item.id===cardDetail.merchantId)?.name : undefined,
      total: saleAmount + refundAmount,
      surcharge:saleSurchargeAmount+refundSurchargeAmount,
      subtotal:saleSubtotalAmount+refundSubtotalAmount,
      [TransactionAction.Sale]: {
        transactionCount: saleItem?.transactionCount || 0,
        amount: saleAmount || 0,
        surcharge:Number(saleItem?.surcharge?.split("$")[1] || 0),
        subtotal:Number(saleItem?.subtotal?.split("$")[1] || 0),
      },
      [TransactionAction.Refund]: {
        transactionCount: refundItem?.transactionCount || 0,
        amount: refundAmount || 0,
        surcharge:Number(refundItem?.surcharge?.split("$")[1] || 0) * -1,
        subtotal:Number(refundItem?.subtotal?.split("$")[1] || 0) * -1,
      },
    };
    processedData.total += (saleAmount + refundAmount);
    processedData.subtotal += (saleSubtotalAmount + refundSubtotalAmount);
    processedData.surcharge += (saleSurchargeAmount + refundSurchargeAmount);
    processedData[TransactionAction.Sale].totalAmount += saleAmount;
    processedData[TransactionAction.Sale].surcharge += saleSurchargeAmount;
    processedData[TransactionAction.Sale].subtotal += saleSubtotalAmount;
    processedData[TransactionAction.Refund].totalAmount += refundAmount;
    processedData[TransactionAction.Refund].surcharge += refundSurchargeAmount;
    processedData[TransactionAction.Refund].subtotal += refundSubtotalAmount;
    processedData[TransactionAction.Sale].totalTransaction += saleItem?.transactionCount || 0;
    processedData[TransactionAction.Refund].totalTransaction += refundItem?.transactionCount || 0;
    processedData.cardDetails = [...processedData.cardDetails, res];
    return processedData;
  }, processedSummary);
}

export const US_PHONE_REGEX = /^\(?([0-9]{3})\)?[-.\\s]?([0-9]{3})[-.\\s]?([0-9]{4})$/i;

export enum FormValidators {
  PhoneNumber = '[0-9]{3}-[0-9]{3}-[0-9]{4}',
  Email = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]+$'
}

