import {
  Component, ChangeDetectionStrategy, Input,
} from '@angular/core';
import { TransactionStatus } from '@enums';
import { StatusColor } from '@models';
import TransactionLogResponse from '@services/responses/transaction-log.response';
import BatchUtilities from '@shared/utilities/batch.utilities';
import TransactionUtilities from '@shared/utilities/transaction.utilities';

@Component({
  selector: 'transaction-log-list-item',
  templateUrl: './transaction-log-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionLogListItemComponent {
  @Input()
    log: TransactionLogResponse;

  transactionStatuses = TransactionStatus;

  collapsed: boolean = false;

  get transactionStatusColor(): StatusColor {
    return TransactionUtilities.getStatusColorClass(this.log.transactionStatus!);
  }

  get batchStatusColor(): StatusColor {
    return BatchUtilities.getStatusColorClass(this.log.batchStatus!);
  }
}
