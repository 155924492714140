import { Injectable } from '@angular/core';
import { createHttpQueryParams } from '@helpers/utils';
import { Batch, PaginatedResponse } from '@models';
import { BatchDetailExport, BatchExport } from '@models/batch-export';
import { TransactionsSummary } from '@models/batch-summary';

import { BaseService } from './base.service.ts';
import { BatchTableQueryParams } from './queries/batch-table-query-params';

@Injectable({ providedIn: 'root' })
export class BatchService extends BaseService {
  listBatches(query: BatchTableQueryParams) {
    const params = createHttpQueryParams(query);

    return this.get<PaginatedResponse<Batch>>('/batches', params);
  }

  getExportBatches(query: BatchTableQueryParams) {
    const params = createHttpQueryParams(query);

    return this.get<BatchExport[]>('/exports/batches', params);
  }

  getExportBatchWithDetails(batchId: number) {
    return this.get<BatchDetailExport>(`/exports/batches/${batchId}`);
  }

  getBatch(id: number) {
    return this.get<Batch>(`/batches/${id}`);
  }

  getBatchSummary(id: number) {
    return this.get<TransactionsSummary>(`/batches/batchSummary/${id}`);
  }

  forceCloseBatch(id: number) {
    return this.post<Batch>(`/batches/${id}`);
  }

  getCurrentBatch() {
    return this.get<Batch | null>('/batches/current');
  }
  sendBatchSummaryEmail(id: number, email:string ) {
    return this.post(`/batches/${id}/batch-summary-email`, {email});
  }
}
