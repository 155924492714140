import {
  Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter,
} from '@angular/core';

@Component({
  selector: 'detail-section',
  templateUrl: './detail-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailSectionComponent implements OnInit {
  @Output() editToggle = new EventEmitter<boolean>(false);

  @Output() save = new EventEmitter();

  @Input() name: string;

  @Input() enableEditToggle = false;

  @Input() enableSave = true;

  edit = false;

  constructor() { }

  ngOnInit(): void {
  }

  onEditToggle() {
    this.edit = !this.edit;

    this.editToggle.emit(this.edit);
  }

  onSave() {
    this.save.emit();
    this.edit = false;
  }
}
