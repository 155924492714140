import {
  Component, OnInit, ChangeDetectionStrategy, Input,
} from '@angular/core';
import { PaymentPlanInstallmentStatus } from '@enums';
import PaymentPlanUtilities from '@shared/utilities/payment-plan.utilities';

@Component({
  selector: 'payment-plan-installment-status[status]',
  templateUrl: './payment-plan-installment-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentPlanInstallmentStatusComponent implements OnInit {
  @Input() status!: PaymentPlanInstallmentStatus;

  constructor() { }

  ngOnInit(): void {
  }

  get backgroundClass(): string {
    return PaymentPlanUtilities.getInstallmentStatusBackgroundClass(this.status);
  }
}
