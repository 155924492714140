import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { formatCentsToDollars } from '@helpers/utils';

@Pipe({ name: 'centsAsDollars' })
export class CentsAsDollarsPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) { }

  transform(value?: number) {
    if (value === undefined) {
      return '';
    }

    const dollars = formatCentsToDollars(value);

    return this.currencyPipe.transform(dollars)!;
  }
}
