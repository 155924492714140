import {Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TransactionAction } from '@enums';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-export-template',
  templateUrl: './export-template.component.html'
})
export class ExportTemplateComponent {

  @Input() exportContent:any;
  @Input() exportColumns:any;
  @Input() detailExportContent?:any;
  @Input() detailExportColumns?:any;
  @Input() batchSummaryDetail?:any;
  @Input() filterContent?:any;
  @ViewChild('template', { static: false }) template: ElementRef;
  TransactionAction = TransactionAction;

  constructor(
    private notificationService: NzNotificationService,
    private http: HttpClient){}

  ngAfterViewInit(){
    try{
      if(!this.exportContent.length){
        this.notificationService.error('No data to Print', '');
        return;
      }
    this.http.get('assets/logo.png', { responseType: 'blob' }).subscribe((blob: Blob) => {
      this.imageToBase64(blob).subscribe((imageAsBase64: string) => {
       const blob = new Blob([this.template.nativeElement.innerHTML], { type: 'text/html' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe: HTMLIFrameElement = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.onload = () => {
        const img = iframe.contentDocument?.querySelector('img');
        if (img) {
          img.src = imageAsBase64;
          if (this.isSafariBrowser()) {
            setTimeout(() => {
              iframe.contentWindow?.print();
            }, 500);
          } else {
            iframe.contentWindow?.print();
          }
        }
};

      });
    });
    }catch(error){
      this.notificationService.error('PDF', 'There was an error loading the PDF');
    }
  }
  isSafariBrowser() {
    const agent = window.navigator.userAgent.toLowerCase()

    switch (true) {

      case agent.indexOf('opr') > -1 && !!(<any>window).opr:

        return false;

      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:

        return false;

      case agent.indexOf('safari') > -1:

        return true;

      default:

        return false;

    }
  }

  imageToBase64(blob: Blob): Observable<string> {
    return new Observable<string>((observer) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        observer.next(reader.result?.toString() || '');
        observer.complete();
      };
      reader.readAsDataURL(blob);
    });
  }

   getObjectValues(object: any){
    return Object.values(object);
  }
    getObjectKeys(object:any) {
      return Object.keys(object);
    }

    hasValues(object: any): boolean {
      return Object.values(object).some(value => !!value);
}

}
