import { Injectable } from '@angular/core';
import { createHttpQueryParams } from '@helpers/utils';
import { PaginatedResponse, Transaction } from '@models';

import { BaseService } from './base.service.ts';
import { Text2PayTableQueryParams } from './queries/text2pay-table-query-params';
import CreateText2PayPaymentRequest from './requests/create-text2pay-payment.request';
import CreateText2PayMessageRequest from './requests/create-text2pay.request';
import Text2PayMessageResponse from './responses/text2pay-message.response';
import Text2PayPaymentResponse from './responses/text2pay-payment.response';
import Text2PayResponse from './responses/text2pay.response';
import Text2PayListResponse from './responses/text2paylist.response.js';

@Injectable({ providedIn: 'root' })
export class Text2PayService extends BaseService {
  listText2Pay(query: Text2PayTableQueryParams) {
    const params = createHttpQueryParams(query);

    return this.get<PaginatedResponse<Text2PayListResponse>>('/text2pay', params);
  }

  getText2Pay(id: number) {
    return this.get<Text2PayResponse>(`/text2pay/${id}`);
  }

  getText2PayMessages(id: number, sort: 'asc' | 'desc') {
    return this.get<Text2PayMessageResponse[]>(`/text2pay/${id}/messages?sort=${sort}`);
  }

  createText2Pay(request: CreateText2PayMessageRequest) {
    return this.post<Text2PayResponse>('/text2pay', request);
  }

  getText2PayPayment(token: string) {
    return this.get<Text2PayPaymentResponse>(`/text2pay/messages/${token}`);
  }

  createText2PayPayment(token: string, request: CreateText2PayPaymentRequest) {
    return this.post<Transaction>(`/text2pay/messages/${token}`, request);
  }

  resendText2PayMessage(id: number) {
    return this.post(`/text2pay/${id}/messages`);
  }

  cancelText2Pay(id: number) {
    return this.delete(`/text2pay/${id}`);
  }
}
