/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnauthorizedComponent } from '@components/unauthorized/unauthorized.component';
import { AuthGuard } from '@helpers';
import { PermissionGuard } from '@helpers/permission.guard';
import { HomeComponent } from '@modules/home';
import { Permissions } from '@enums';

import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

const privateRoutes: Routes = [

  { path: '', pathMatch: 'full', component: HomeComponent , canActivate: [PermissionGuard.forPermissions([Permissions.DASHBOARD_VIEW])] },
  { path: 'virtual-terminal', loadChildren: () => import('./modules/virtual-terminal/virtual-terminal.module').then((m) => m.VirtualTerminalModule) },
  { path: 'transactions', loadChildren: () => import('./modules/transactions/transactions.module').then((m) => m.TransactionsModule) },
  { path: 'payment-plans', loadChildren: () => import('./modules/payment-plans/payment-plans.module').then((m) => m.PaymentPlansModule) },
  { path: 'batches', loadChildren: () => import('./modules/batches/batches.module').then((m) => m.BatchesModule) },
  { path: 'reporting', loadChildren: () => import('./modules/reporting/reporting.module').then((m) => m.ReportingModule) },
  { path: 'settings', loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule) },
  { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule) },
  {path: 'text2pay', loadChildren: () => import('./modules/text2pay/text2pay.module').then((m) => m.Text2payModule) },
  {path: 'help', loadChildren: () => import('./modules/help/help.module').then((m) => m.HelpModule) },
  { path: 'unauthorized', component: UnauthorizedComponent },
];

const routes: Routes = [
  {
    path: '', component: MainLayoutComponent, children: privateRoutes, canActivate: [AuthGuard],
  },
  { path: 'account', loadChildren: () => import('./modules/account/account.module').then((m) => m.AccountModule) },
  { path: 'p', data: { detachLayout:true }, loadChildren: () => import('./modules/public/public.module').then((m) => m.PublicModule) },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
