/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Component, OnInit, Input, ElementRef, ViewChild, HostListener, OnChanges, SimpleChanges,
} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MerchantService } from '@services';
import * as dayjs from 'dayjs';
import * as isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore';
import { environment } from 'environments/environment';
import { NzNotificationService } from 'ng-zorro-antd/notification';

// eslint-disable-next-line import/extensions
import * as theme from '../../../../../theme.js';
import CardUtilities from '../../utilities/card.utilities';

dayjs.extend(isSameOrBeforePlugin);

const MIN_CREDIT_CARD_NUMBER_LENGTH = 13;

@Component({
  selector: 'card-information',
  templateUrl: './card-information.component.html',
})
export class CardInformationComponent implements OnInit, OnChanges {
  @Input() formGroupName: string;

  @Input() cardPresentToggle = false;

  @Input() readonly = false;

  @Input() readonlyCardAndExpiry = false;

  @Input() showCardNumberError=false;

  @Input() hideToggle=false;

  isCardReaderChecked = false;

  showCardReaderModal = false;

  cardReaderOutput: string = '';

  form: FormGroup;

  // eslint-disable-next-line max-len
  iframeBaseUrl = `${environment.tokenizerBaseUrl}?invalidinputevent=true&enhancedresponse=true&formatinput=true&placeholder=Card%20Number&sendcssloadedevent=true`;

  iframeUrl: SafeResourceUrl;

  iframeLoading = true;

  tokenizedValue: string;

  isIframeDisabled = false;

  cardBrand?:string;

  months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];

  yearThreshold = 15;

  years: string[] = [];

  showToggle = false;

  @ViewChild('cardInput', { static: false }) cardInput: ElementRef;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private notificationService: NzNotificationService,
    private sanitizer: DomSanitizer,
    private merchantService: MerchantService,
  ) {
    this.setEnabledIframe();
  }

  setIframe(style: string) {
    this.iframeLoading = true;
    const css = encodeURIComponent(style);
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.iframeBaseUrl}&css=${css}`);
  }

  setEnabledIframe() {
    this.isIframeDisabled = false;
    this.setIframe(this.inputStyle);
  }

  setDisabledIframe() {
    this.isIframeDisabled = true;
    this.setIframe(this.disabledInputStyle);
  }

  ngOnInit(): void {
    this.merchantService.config.subscribe((config) => {
      if(this.hideToggle)
      {
        this.showToggle=false;
      }
      else{
      this.showToggle = config.terminalTransactionAllowed;
      }
    });

    const end = dayjs().add(this.yearThreshold, 'years');

    for (let current = dayjs(); current.isSameOrBefore(end); current = current.add(1, 'year')) {
      this.years.push(current.format('YYYY'));
    }

    if (this.formGroupName) {
      this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    } else {
      this.form = this.rootFormGroup.control;
    }
    this.form.get('present')?.valueChanges.subscribe((isCardPresent) => {
      const controls = ['expireMonth', 'expireYear', 'zipCode'];

      // eslint-disable-next-line no-restricted-syntax
      for (const controlName of controls) {
        if (isCardPresent) {
          this.form.get(controlName)?.disable();
        } else {
          this.form.get(controlName)?.enable();
        }
      }

      if (isCardPresent) {
        this.setDisabledIframe();
      } else {
        this.setEnabledIframe();
      }
    });
  }

  get cardNumberValidation(){
    return this.showCardNumberError && !this.form.get('account')?.valid;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.readonly) {
      if (changes.readonly.currentValue === true) {
        this.iframeLoading = true;
      }
    }
  }

  get isCardPresent() {
    const control = this.form.get('present');

    if (control) {
      return !!control.value;
    }

    return false;
  }

  get cardNumberPattern() {
    if (this.readonly) {
      // Allow asterik (*) character when card is readonly to allow display of
      // last 4 digits of the card with a pattern of: ****-****-****-XXXX
      return { 0: { pattern: new RegExp('^[0-9*]*$') } };
    }

    return { 0: { pattern: new RegExp('^[0-9]*$') } };
  }

  onSwiperClick() {
    this.isCardReaderChecked = true;
    this.showCardReaderModal = true;
    this.resetCardReaderFields();
  }

  onCancelCardReaderModal() {
    this.isCardReaderChecked = false;
    this.showCardReaderModal = false;
    this.cardReaderOutput = '';
    this.iframeLoading=true;
    this.resetCardReaderFields();
  }

  // Re-focus the hidden input field when user clicks outside the field
  onCardInputBlur(event: FocusEvent) {
    this.cardInput.nativeElement.focus();
  }

  /**
   * Decode the credit card data from the card reader (MSR) device
   *
   * This logic is based on the output format (taken from a test card):
   *
   *
   * "%B5413330089099130^UAT USA/TEST CARD 20     ^22122011483594900000000000000000?;5413330089099130=22122011483594900000?"
   *
   * It should decode these values:
   *
   * Card Number: 5413330089099130
   * Card Holder: TEST CARD 20 UAT USA
   * Card Expiry Month: 12
   * Card Expiry Year: 2022
   */
  onCardReadingComplete() {
    const output = this.cardReaderOutput;

    const firstCaretIdx = output.indexOf('^');
    const trackIndex = output.indexOf(';');
    const lastCaretIdx = output.lastIndexOf('^');
    const semiColonIdx = output.indexOf(';');
    const equalIdx = output.indexOf('=');

    const cardHolderReversed = output.substring(firstCaretIdx + 1, lastCaretIdx).trim().split('/');

    const cardHolder = `${cardHolderReversed[1]} ${cardHolderReversed[0]}`;
    const trackData = output.substring(trackIndex);

    const cardNumber = output.substring(semiColonIdx + 1, equalIdx);
    const expiryMonth = output.substring(lastCaretIdx + 3, lastCaretIdx + 5);
    const expiryYear = `20${output.substring(lastCaretIdx + 1, lastCaretIdx + 3)}`;

    this.cardReaderOutput = '';
    this.showCardReaderModal = false;

    if (!this.isValidCardData(cardNumber, expiryMonth, expiryYear)) {
      this.isCardReaderChecked = false;
      this.notificationService.error('Error', 'Could not read card. Please try again.');
      this.resetCardReaderFields();
    } else {
      this.form.controls.cardReaderData.setValue(`${'****'.repeat(3)}${cardNumber.slice(-4)}`);
      this.form.controls.holder.setValue(cardHolder);
      this.form.controls.expireMonth.setValue(expiryMonth);
      this.form.controls.expireYear.setValue(expiryYear);
      this.form.controls.trackData.setValue(trackData);
    }
  }

  resetCardReaderFields() {
    this.form.controls.cardReaderData.setValue('');
    this.form.controls.account.setValue('');
    this.form.controls.holder.setValue('');
    this.form.controls.expireMonth.setValue('');
    this.form.controls.expireYear.setValue('');
    this.form.controls.trackData.setValue('');
  }

  isValidCardData(cardNumber: string, expireMonth: string, expireYear: string) {
    if (cardNumber.length < MIN_CREDIT_CARD_NUMBER_LENGTH) return false;
    if (!this.months.includes(expireMonth)) return false;
    if (!this.years.includes(expireYear)) return false;

    return true;
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    this.cardBrand=undefined;
    if (typeof event.data === 'string') {
      const response = JSON.parse(event.data);

      if ('cssLoaded' in response) {
        this.iframeLoading = !response.cssLoaded;
        return;
      }

      this.tokenizedValue = response.token;
      this.setTokenValue(response.token);
    }
  }

  setTokenValue(token: string) {
    this.cardBrand=CardUtilities.getCardBrand(token);
    this.tokenControl.setValue(token);
    this.tokenControl.updateValueAndValidity();
  }

  get tokenControl() {
    return this.form.get('account')!;
  }

  inputStyle = `
    body {
      display: inline-block;
      margin: 0;
      width: 100%;
    }

    input {
      box-sizing: border-box;
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 8px 11px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      border-color: transparent;
    }

    input::placeholder {
      color: #bfbfbf;
    }

    input:hover {
      border-color: #6eaaff;
      border-right-width: 1px !important;
    }

    input:focus {
      border-color: #6eaaff;
      box-shadow: 0 0 0 2px rgb(66 133 244 / 20%);
      border-right-width: 1px !important;
      outline: 0;
    }

    .error {
      border-color: ${theme.invalidInput};
    }
  `;

  disabledInputStyle = `
    body {
      display: inline-block;
      margin: 0;
      width: 100%;
    }

    input {
      box-sizing: border-box;
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 8px 11px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
      box-shadow: none;
      cursor: not-allowed;
    }

    input::placeholder {
      color: #bfbfbf;
    }

    input:hover {
      border-color: #6eaaff;
      border-right-width: 1px !important;
    }

    input:focus {
      border-color: #6eaaff;
      box-shadow: 0 0 0 2px rgb(66 133 244 / 20%);
      border-right-width: 1px !important;
      outline: 0;
    }
  `;
}
