import { CurrencyPipe } from '@angular/common';
import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { formatCentsToDollars } from '@helpers/utils';
import { User } from '@models';
import { DashboardReportResponse, PaymentAnalyticsResponse, PaymentGroup } from '@models/dashboard-report.response';
import { AccountService, HeaderService, ReportService } from '@services';
import * as dayjs from 'dayjs';
import {
  ApexNonAxisChartSeries, ApexChart, ApexResponsive, ApexNoData, ApexYAxis,
} from 'ng-apexcharts';
import { Subscription } from 'rxjs';

export type ChartOptions = {
  title: string,
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: string[];
  noData: ApexNoData;
  yaxis: ApexYAxis;
};

interface ChartFormatterOptions {
  dataPointIndex: number;
  seriesIndex: number;
  config: {
    chart: ApexChart;
  }
}

enum ChartCategory {
  ProcessingMethod = 'ProcessingMethod',
  CardType = 'CardType',
}

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent implements OnInit, OnDestroy {
  user: User;

  today: dayjs.Dayjs;

  /*
   Needs to be type Date instead of Dayjs because of 2 ways data binding
   on this property through date picker.
   */
  paymentAnalyticDate: Date;

  report: DashboardReportResponse;

  paymentAnalytics: PaymentAnalyticsResponse;

  getReports$: Subscription;

  getPaymentAnalytics$: Subscription;

  processingMethodChartOptions: ChartOptions;

  cardTypeChartOptions: ChartOptions;

  constructor(private accountService: AccountService, private headerService: HeaderService,
    private reportService: ReportService, private currencyPipe: CurrencyPipe) {
    this.user = this.accountService.userValue;
  }

  ngOnInit(): void {
    this.headerService.setTitle('Dashboard');
    this.today = dayjs();
    this.paymentAnalyticDate = dayjs().toDate();

    this.getReports$ = this.reportService.getReports()
      .subscribe((report) => this.report = report);

    this.getPaymentAnalytics();
  }

  ngOnDestroy(): void {
    this.getReports$?.unsubscribe();
    this.getPaymentAnalytics$?.unsubscribe();
  }

  onChangePaymentAnalyticsMonth() {
    this.getPaymentAnalytics();
  }

  private getPaymentAnalytics() {
    const date = dayjs(this.paymentAnalyticDate).startOf('month').toISOString();

    this.getPaymentAnalytics$ = this.reportService.getPaymentAnalytics({ date })
      .subscribe((paymentAnalytics) => {
        this.paymentAnalytics = paymentAnalytics;
        this.processingMethodChartOptions = this.getChartOptions(ChartCategory.ProcessingMethod, 'Processing Method',
          this.paymentAnalytics.processingMethod);
        this.cardTypeChartOptions = this.getChartOptions(ChartCategory.CardType, 'Card Type',
          this.paymentAnalytics.cardType);
      });
  }

  private getChartOptions(id: string, title: string, groups: PaymentGroup[]): ChartOptions {
    return {
      title,
      series: groups.map((paymentGroup) => formatCentsToDollars(Math.abs(paymentGroup.amount))),
      chart: {
        type: 'donut',
        id,
      },
      labels: groups.map((paymentGroup) => paymentGroup.label),
      responsive: [
        {
          // Makes this options be applied for all screen sizes
          breakpoint: window.innerWidth + 1,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
      noData: {
        text: 'No data to display',
      },
      yaxis: {
        labels: {
          formatter: (value, { seriesIndex, config: { chart } }: ChartFormatterOptions) => {
            switch (chart.id) {
              case ChartCategory.ProcessingMethod:
                return this.currencyPipe
                  .transform(formatCentsToDollars(this.paymentAnalytics.processingMethod[seriesIndex].amount || 0))!;
              case ChartCategory.CardType:
                return this.currencyPipe
                  .transform(formatCentsToDollars(this.paymentAnalytics.cardType[seriesIndex].amount || 0))!;
              default:
                return this.currencyPipe.transform(value || 0)!;
            }
          },
        },
      },
    };
  }
}
