import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DatePickerType,
  TransactionAction, TransactionSource, TransactionStatus, TransactionType,
} from '@enums';
import { SendReceiptState } from '@enums/send-receipt-state';
import { Transaction, TransactionExport } from '@models';
import { ExportService, TransactionService } from '@services';
import { FilterService } from '@services/filter.service';
import { PrintPdfService } from '@services/print-pdf.service';
import { TransactionTableQueryParams } from '@services/queries/transaction-table-query-params';
import { BaseComponent } from '@shared/directives/base.component';
import ExportUtilities from '@shared/utilities/export-utilities';
import * as dayjs from 'dayjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'transaction-list',
  templateUrl: './transaction-list.component.html',
})
export class TransactionListComponent extends BaseComponent implements OnInit {
  @Input() batchId?: number;

  @Input() defaultFilterType?: DatePickerType;

  @Output() onExportAction = new EventEmitter();

  @Output() onPrintAction = new EventEmitter();

  @ViewChild('exportModalInProgress') exportModalInProgress: TemplateRef<any>;

  @ViewChild('exportModalComplete') exportModalComplete: TemplateRef<any>;

  getTransactionState$: Subscription | undefined;

  transactions: Transaction[] = [];

  loading = false;

  total = 0;

  transactionActions = TransactionAction;

  TransactionTypes = TransactionType;

  selectedTransaction: Transaction;

  refundModalOpen = false;

  voidModalOpen = false;

  sendReceiptStates = SendReceiptState;

  sendReceiptState: SendReceiptState | undefined;

  pdfLoading=false;

  exportContent:TransactionExport[];

  filterContent:any;

  exportColumns = [
    'Reference Number',
    'Internal ID',
    'Card Holder',
    'Last 4 digits',
    'Card Network',
    'Subtotal',
    'Total',
    'Surcharge',
    'Status',
    'Date',
    'Timestamp',
    'Type',
    'Authorization Code',
    'Invoice Number',
    'Amount Funded',
    'Funding Date'
  ];

  tableParams: TransactionTableQueryParams = {
    pageSize: 50,
    page: 1,
  };

  defaultAction: TransactionAction[];

  defaultSources:TransactionSource[];
  defaultStatuses:TransactionStatus[];
  defaultDates: Date[];
  defaultAmount:number;
  defaultAmountRange:number[];
  defaultDateType=DatePickerType.WEEK;

  isInitialListCall=true;

  constructor(
    private transactionService: TransactionService,
    public exportService: ExportService,
    private notificationService: NzNotificationService,
    private printPdfService: PrintPdfService,
    private activatedRoute: ActivatedRoute,
    private filterService:FilterService,
  ) {
    super();
  }

  ngOnInit(): void {
    const { amt,amtrg,dtrg, act,src,sts,dttp } = this.activatedRoute.snapshot.queryParams;
    this.defaultAmount=amt;
    this.defaultAmountRange=amtrg;
    this.defaultAction=(act && Array.isArray(act))?[...act]:[act];
    this.defaultSources=(src && Array.isArray(src))?[...src]:[src];
    if(sts && Array.isArray(sts)){
      this.defaultStatuses=[...sts];
    }else if(sts){
      this.defaultStatuses=[sts];
    }
    if(dttp){
      this.defaultDateType=dttp==='all'?DatePickerType.DATE:dttp
    };
    if (dtrg && Array.isArray(dtrg)) {
      this.defaultDates = dtrg.map(item => new Date(item));
    }else if(dtrg){
      this.defaultDates=[new Date(dtrg)]
    }
    if(this.defaultFilterType)
    this.defaultDateType=this.defaultFilterType
    const {batchId}=this.activatedRoute.snapshot.params
    if(batchId)
    this.tableParams.batchId = batchId;
  }


  ngOnDestroy() {
    if (this.getTransactionState$) {
      this.getTransactionState$.unsubscribe();
    }
  }

  applyFilters() {
    this.tableParams.page = 1;
    
    this.listTransactions();
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const {
      pageSize,
      pageIndex,
      sort,
    } = params;

    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    this.tableParams = {
      ...this.tableParams,
      page: pageIndex,
      pageSize,
      sortField,
      sortOrder,
    };

    this.listTransactions();
  }

  onSearch(searchQuery: string | undefined) {
    this.tableParams.search = searchQuery;
    if(!this.isInitialListCall) this.applyFilters();
  }

  onDateFilterChange(event:{filter:string | string[],type:DatePickerType}) {
    let dateRange:string[]=[];
    if(event){
      const {filter,type}=event;
      if (filter instanceof Array) {
        const [from, to] = filter;
        
        this.tableParams.date = undefined;
        this.tableParams.dateFrom = from;
        this.tableParams.dateTo = to;
        dateRange= filter
      } else {
        this.tableParams.date = filter;
        this.tableParams.dateFrom = undefined;
        this.tableParams.dateTo = undefined;
        dateRange=filter?[filter]:[];
      }
      if(type===DatePickerType.DATE || type===DatePickerType.RANGE){
        this.filterService.addFilterToQueryParam(this.activatedRoute,{dtrg:dateRange,dttp:type});
      }else{
        this.filterService.addFilterToQueryParam(this.activatedRoute,{dtrg:[],dttp:type});
      }
    }else{
      this.tableParams.date = undefined;
      this.tableParams.dateFrom = undefined;
      this.tableParams.dateTo = undefined;
      this.filterService.addFilterToQueryParam(this.activatedRoute,{dtrg:[],dttp:'all'});
    }

    if(!this.isInitialListCall) this.applyFilters();
  }

  onAmountFilterChange(amount: number | number[]) {
    let amtrg:number[]=[];
    let customAmount:number[]=[];
    if (amount instanceof Array) {
      const [from, to] = amount;
      this.tableParams.amount = undefined;
      this.tableParams.amountFrom = from;
      this.tableParams.amountTo = to;
      amtrg=amount;
    } else {
      this.tableParams.amount = amount;
      this.tableParams.amountFrom = undefined;
      this.tableParams.amountTo = undefined;
      customAmount=amount?[amount]:[];
    }
    this.filterService.addFilterToQueryParam(this.activatedRoute,{amtrg,amt:customAmount});

    if(!this.isInitialListCall) this.applyFilters();
  }

  onActionFilterChange(actions: TransactionAction[]) {
    this.tableParams.action = actions;
    this.filterService.addFilterToQueryParam(this.activatedRoute,{act:actions});
    if(!this.isInitialListCall) this.applyFilters();
  }

  onSourceFilterChange(sources: TransactionSource[]) {
    this.tableParams.source = sources;
    this.filterService.addFilterToQueryParam(this.activatedRoute,{src:sources});
    if(!this.isInitialListCall) this.applyFilters();
  }

  onStatusFilterChange(statuses: TransactionStatus[]) {
    this.tableParams.status = statuses;
    this.filterService.addFilterToQueryParam(this.activatedRoute,{sts:statuses});
    if(!this.isInitialListCall) this.applyFilters();
  }



  refundOrVoid(transaction: Transaction) {
    this.selectedTransaction = transaction;
    this.getTransactionState$ = this.transactionService
      .getTransactionState(transaction.id)
      .subscribe((transactionState) => {
        if (transactionState.voidable) {
          this.voidModalOpen = true;
        } else if (transactionState.refundable) {
          this.refundModalOpen = true;
        } else {
          this.notificationService.error('This transaction cannot be voided or refunded', '');
        }
      });
  }

  onExport() {
    const shouldOverrideExport = this.onExportAction.observed;
    if (shouldOverrideExport) {
      this.onExportAction.emit();
      return;
    }

    this.transactionService
      .getExportTransactions(this.tableParams).subscribe((response) => {
        response = this.exportService.formatInvoiceNumber(response);
        this.exportService.formatRows(response);
        response.forEach((item)=>{
        ExportUtilities.removeRows(item)
       });
        this.exportService.export(response, this.exportColumns, this.exportModalInProgress, this.exportModalComplete);
      });
  }

  onExportCancel() {
    this.exportService.cancel();
  }

  onExportDownload(blob: Blob) {
    this.exportService.download(blob);
  }

  onPrint(){  
    const shouldOverrideExport = this.onPrintAction.observed;
    if (shouldOverrideExport) {
      this.onPrintAction.emit();
      return;
    }
    this.pdfLoading=true;

    let date
    if (this.tableParams.date) date = dayjs(this.tableParams.date).format('DD/MM/YYYY');
    else if (this.tableParams.dateFrom && this.tableParams.dateTo) 
      date = `${dayjs(this.tableParams.dateFrom).format('DD/MM/YYYY')} - ${dayjs(this.tableParams.dateTo).format('DD/MM/YYYY')}`;
    else date = undefined;

    let amount
    if(this.tableParams.amount) amount = `$${this.tableParams.amount}`
    else if (this.tableParams.amountFrom && this.tableParams.amountTo ) 
    amount = `$${this.tableParams.amountFrom} - $${this.tableParams.amountTo}`
    else amount = undefined;

    this.filterContent={
        'Search':this.tableParams.search,
        'Date': date,
        'Amount':amount,
        'Transaction Type':this.tableParams?.action && this.tableParams.action.length > 0 ? this.tableParams.action : undefined,
        'Source' : this.tableParams?.source && this.tableParams.source.length > 0 ? this.tableParams.source : undefined,
        'Status':this.tableParams?.status && this.tableParams.status.length > 0 ? this.tableParams.status : undefined
    }
    this.transactionService
      .getExportTransactions(this.tableParams).subscribe((response) => {
        this.exportContent=response;
        this.exportService.formatRows(this.exportContent);
        this.exportContent.forEach((item)=>{
        ExportUtilities.removeRows(item)
       })
        this.pdfLoading=false;
      });
  }

  onRefundModalClose() {
    this.refundModalOpen = false;
    this.listTransactions();
  }

  onVoidModalClose() {
    this.voidModalOpen = false;
    this.listTransactions();
  }

  private listTransactions() {
    this.loading = true;

    this.transactionService.listTransactions(this.tableParams).subscribe((response) => {
      this.total = response.total;
      this.transactions = response.results;
      this.loading = false;
      this.isInitialListCall=false;
    });
  }

  onClickReceiptOption(transaction: Transaction, action: 'print' | 'sms' | 'email',type='') {
    this.selectedTransaction = transaction;

    switch (action) {
      case 'print':
        this.sendReceiptState = SendReceiptState.PRINT;
        this.printPdfService.printTransactionReceipt(transaction.id,type).subscribe({
          next: () => this.sendReceiptState = undefined,
          error: () => this.sendReceiptState = undefined,
        });
        break;
      case 'email':
        this.sendReceiptState = SendReceiptState.SEND_BY_EMAIL;
        break;
      case 'sms':
        this.sendReceiptState = SendReceiptState.SEND_BY_SMS;
        break;
      default:
        break;
    }
  }

  getReferenceNumber(transaction: Transaction) {
    if (transaction.retref) {
      return transaction.retref;
    }

    if (transaction.legacyReferenceNumber) {
      return `L-${transaction.legacyReferenceNumber}`;
    }

    return null;
  }
}
