import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MerchantService } from '@services';

@Component({
  selector: 'location-selected-input',
  templateUrl: './location-selected-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationSelectedInputComponent implements OnInit {
  constructor(public merchantService: MerchantService) { }

  ngOnInit(): void {
  }
}
