<div nz-dropdown [nzDropdownMenu]="dropdown" nzTrigger="click" [(nzVisible)]="dropdownOpen" class="h-10 rounded border flex items-center justify-between px-3 py-2 text-label cursor-pointer select-none">
    <span class="whitespace-nowrap overflow-hidden overflow-ellipsis">Transaction Type</span>
    <icon name="chevron-down" iconClass="w-4"></icon>
</div>
<nz-dropdown-menu #dropdown>
    <div class="bg-white border rounded p-4">
        <nz-checkbox-group class="flex flex-col gap-2" [(ngModel)]="transactionTypes"></nz-checkbox-group>

        <div class="flex gap-2 mt-3">
            <button-default class="flex-1" (click)="dropdownOpen = false">Cancel</button-default>
            <button nz-button nzType="primary" type="submit" class="flex-1" (click)="onApplyFilter()">Apply Filter</button>
        </div>
    </div>
</nz-dropdown-menu>
<div class="flex flex-col gap-1 mt-2" *ngIf="selectedOptions.length">
    <div *ngFor="let option of selectedOptions" class="flex items-start justify-between bg-light py-1 px-2 text-xs">
        {{option.label}}
        <icon name="x" class="cursor-pointer" iconClass="w-4" (click)="removeOption(option)"></icon>
    </div>
</div>