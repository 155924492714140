import { inject, InjectionToken } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Permissions } from '@enums';
import PermissionUtilities from '@shared/utilities/permission.utilities';

export class PermissionGuard {
  static forPermissions(permissions: Permissions[],redirectUrl?:string) {
    return new InjectionToken<CanActivate>('PermissionGuard', {
      providedIn: 'root',
      factory: () => {
        const router = inject(Router);

        return {
          canActivate(): boolean {
            const hasPermission = PermissionUtilities.hasPermission(...permissions);
            if (!hasPermission) {
                if(redirectUrl)
                router.navigate([redirectUrl]);
               else if(PermissionUtilities.hasPermission(Permissions.VIRTUAL_TERMINAL_CREATE))
                  router.navigate(['/virtual-terminal']);
                else
                router.navigate(['/unauthorized']);
                return false;
            }
           

            return hasPermission;
          },
        };
      },
    });
  }

}
