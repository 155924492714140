import {
  Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy,
} from '@angular/core';
import { TransactionState } from '@models';
import { TransactionService } from '@services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'transaction-void-modal',
  templateUrl: './transaction-void-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionVoidModalComponent implements OnDestroy {
  @Output() close = new EventEmitter();

  @Input() open = false;

  @Input() transactionId: number;

  @Input() transactionState: TransactionState;

  voidTransaction$: Subscription | undefined;

  constructor(private transactionService: TransactionService) { }

  ngOnDestroy(): void {
    if (this.voidTransaction$) {
      this.voidTransaction$.unsubscribe();
    }
  }

  onClose() {
    this.close.emit();
  }

  onConfirm() {
    this.voidTransaction$ = this.transactionService
      .voidTransaction(this.transactionId)
      .subscribe(() => this.close.emit());
  }
}
