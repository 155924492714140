<div class="mt-4 block bg-white rounded-lg border">
  <div class="flex justify-between p-4">
    <div class="flex flex-col">
      <span class="text-2sm font-bold">{{log.date | dateTimeFormat }}</span>
      <span class="text-2sm text-gray-500">{{log.label}}</span>
    </div>
    <div class="flex flex-col">
      <div class="flex items-center" *ngIf="log.transactionId || log.batchId">
        <span *ngIf="log.amount" class="text-2xl">{{log.amount | centsAsDollars}}</span>
        <icon (click)="collapsed = !collapsed" class="ml-4 cursor-pointer select-none" [name]="'chevron-down'" iconClass="w-5"></icon>
      </div>
      <span *ngIf="log.transactionStatus === transactionStatuses.Error" class="text-2sm text-error">Payment Error</span>
    </div>
  </div>
  <hr *ngIf="collapsed" class="mb-2"/>
  <div *ngIf="collapsed" class="flex flex-col w-full p-4 pt-0">
    <detail-field *ngIf="log.transactionStatus" name="Status" [value]="log.transactionStatus" [color]="transactionStatusColor"></detail-field>
    <detail-field *ngIf="log.transactionRetref" name="Reference Number" [value]="log.transactionRetref"></detail-field>
    <detail-field *ngIf="log.transactionMadeBy" name="Made By" [value]="log.transactionMadeBy"></detail-field>
    <detail-field *ngIf="log.batchStatus" name="Status" [value]="log.batchStatus" [color]="batchStatusColor"></detail-field>

    <a *ngIf="log.transactionId" [routerLink]="'/transactions/' + log.transactionId" class="text-primary font-light self-end mt-2">View Full Details ></a>
    <a *ngIf="log.batchId" [routerLink]="'/batches/' + log.batchId" class="text-primary font-light self-end mt-2">View Full Details ></a>
  </div>
</div>
