module.exports = {
  primary: '#4285F4',
  light: '#EEF4FF',
  default: '#ADADAD',
  success: '#34A853',
  info: '#4285F4',
  error: '#EA4335',
  warning: '#FBBC05',
  invalidInput: '#EA4335',
  borderColor: '#D7D7D7',
  bodyBackgroundColor: '#FFFFFF',
  headerBackgroundColor: '#FFFFFF',
  defaultButtonBackground: '#EDEDED',
  tableRowBackground: 'rgba(247, 247, 247, 0.63)',
  tooltipBackground: '#FFFFFF',
  tooltipColor: '#000000',
  borderRadius: '8px',
  inputHeight: '40px',
  cardBackground: '#F0F0F0',
  visaBackground: '#F0F0F0',
  ccLetterSpacing: '0.18em',
  checkboxRadius: '0px',
  labelColor: '#7A7A7A',
  sectionTitleColor: '#676767',
  helpLabelColor: '#909090',
  heroBackground: '#E5E5E569',
  authPrimary: '#5890B3',
  placeHolder: '#bfbfbf',
  receiptBackgroundColor:'#eee',
  receiptBorderColor:'#ddd',
  receiptTitleBgColor:'#555',
  noteBackground: '#EFF8FF',
  noteBorder:'#BFDEFF',
  remoteAssistanceCardBG:'#eaf5fc',
  cardTextColor:'#474556e7'
};
