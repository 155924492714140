/* eslint-disable no-restricted-syntax */
import { Injectable, TemplateRef } from '@angular/core';
import { TransactionExport } from '@models';
import { BatchDetailExport } from '@models/batch-export';
import { ExportWorkerAction } from 'app/workers/messages/export.worker-message';
import ExportResponse from 'app/workers/responses/export.response';
import * as dayjs from 'dayjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExportService {
  isExporting = new BehaviorSubject(false);

  private currentNotificationId?: string;

  private webWorker: Worker;

  constructor(private notification: NzNotificationService) { }

  export(rows: any[], columns: string[], inProgressModal: TemplateRef<any>, completeModal: TemplateRef<any>) {
    if (!rows.length) {
      this.notification.error('No data to export', '');
      return;
    }

    this.formatRows(rows);

    this.isExporting.next(true);

    this.removeCurrentNotification();

    this.openNotification(inProgressModal);

    this.webWorker = new Worker(new URL('../workers/export.worker', import.meta.url));

    this.webWorker.onmessage = (response: ExportResponse) => {
      this.removeCurrentNotification();
      this.openNotification(completeModal, response.data);
      this.isExporting.next(false);
    };

    this.webWorker.postMessage({
      action: ExportWorkerAction.BeginExport,
      rows,
      columns,
    });
  }

  cancel() {
    this.removeCurrentNotification();
    this.webWorker.terminate();
    this.isExporting.next(false);
  }

  download(blob: Blob) {
    const fileName = `export_${Date.now()}`;
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    this.removeCurrentNotification();
  }

  private removeCurrentNotification() {
    if (this.currentNotificationId) {
      this.notification.remove(this.currentNotificationId);
      this.currentNotificationId = undefined;
    }
  }

  private openNotification(template: TemplateRef<any>, data?: any) {
    const notification = this.notification.template(template, { nzDuration: 0, nzData: data });

    this.currentNotificationId = notification.messageId;
  }

  formatInvoiceNumber (response: TransactionExport[]){
    const key = "invoiceNumber"
    for( const transaction of response){
      if(key in transaction){
        transaction.invoiceNumber = ` ${transaction.invoiceNumber}`;
      }
    }
    return response;
  }

  formatRows(rows: any[]) {
    const FORMAT_VALUE_PREFIX = 'APPLYFORMAT|';

    for (const row of rows) {
      const keys = Object.keys(row);

      for (const key of keys) {
        const value = row[key];

        if (typeof value === 'string') {
          if (value.startsWith(FORMAT_VALUE_PREFIX)) {
            row[key] = this.applyValueFormat(value);
          }
        }
      }
    }
  }

  private applyValueFormat(value: string) {
    const [, type, formattingValue] = value.split('|');

    switch (type) {
      case 'DATE':
        return dayjs(formattingValue).format('MM/DD/YYYY');
      case 'TIME':
        return dayjs(formattingValue).format('HH:mm:ss');
      default:
        return formattingValue;
    }
  }
}
