import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HeaderService {
  title = new BehaviorSubject<string>('');

  setTitle(title: string | undefined) {
    this.title.next(title ?? '');
  }

  clearTitle() {
    this.title.next('');
  }
}
