import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
  init as SentryInit,
  BrowserTracing,
  Replay,
  routingInstrumentation,
} from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

SentryInit({
  dsn: environment.sentryDSN,
  release: environment.releaseTag,
  environment: environment.env,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: routingInstrumentation,
    }),
    new Replay(),
  ],

  tracesSampleRate: environment.env === 'production' ? 0.4 : 1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    environment.apiUrl, /^\//
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
