<div class="flex flex-col items-start w-full">
  <h1 class="text-3xl">Hello, {{user.firstName}}</h1>
  <p class="text-help-label font-semibold">Today is {{today.toDate() | date: 'EEEE, d MMMM, yyyy'}}</p>

  <div *ngIf="report" class="grid grid-cols-3 gap-4 mt-4 mb-4 w-full">
    <transaction-summary-card title="Total Processed Today" [report]="report.todayTransactions">
    </transaction-summary-card>
    <transaction-summary-card title="Total Processed Yesterday" [report]="report.yesterdayTransactions">
    </transaction-summary-card>
    <div class="block bg-white rounded-lg border">
      <div class="text-2sm font-bold px-4 py-2 border-b">Next Scheduled Batch</div>
      <div class="flex flex-col w-full p-4">
        <div class="flex justify-between items-center mb-2">
          <span>Total Amount</span>
          <span class="font-bold text-2xl text-info">{{report.nextBatch.subtotal | centsAsDollars}}</span>
        </div>
        <div class="flex justify-between items-center mb-2">
          <span>Date</span>
          <span class="font-bold">{{report.nextBatch.settledAt | dateFormat}}</span>
        </div>
        <div class="flex justify-between items-center mb-2">
          <span>Time</span>
          <span class="font-bold">{{report.nextBatch.settledAt | timeFormat}}</span>
        </div>
        <div class="flex justify-between items-center">
          <span># Transactions</span>
          <span class="font-bold">{{report.nextBatch.transactionsCount}}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="paymentAnalytics" class="grid grid-cols-4 gap-4 block bg-white rounded-lg border w-full px-8 py-5">
    <div class="flex flex-col items-start">
      <div class="text-lg font-bold">Payment Analytics</div>
      <nz-date-picker
        class="my-2"
        nzMode="month" nzFormat="MMM YYYY" nzPlaceHolder="Select month"
        [(ngModel)]="paymentAnalyticDate"
        (ngModelChange)="onChangePaymentAnalyticsMonth()" name="month">
      </nz-date-picker>
    </div>
    <div class="col-span-3 flex flex-col grid grid-cols-2 gap-4">
      <div>
        <div class="text-label font-bold text-center my-2">{{processingMethodChartOptions.title}}</div>
        <apx-chart
          [series]="processingMethodChartOptions.series"
          [chart]="processingMethodChartOptions.chart"
          [labels]="processingMethodChartOptions.labels"
          [responsive]="processingMethodChartOptions.responsive"
          [noData]="processingMethodChartOptions.noData"
          [yaxis]="processingMethodChartOptions.yaxis"
        ></apx-chart>
      </div>
      <div>
        <div class="text-label font-bold text-center my-2">{{cardTypeChartOptions.title}}</div>
        <apx-chart
          [series]="cardTypeChartOptions.series"
          [chart]="cardTypeChartOptions.chart"
          [labels]="cardTypeChartOptions.labels"
          [responsive]="cardTypeChartOptions.responsive"
          [noData]="cardTypeChartOptions.noData"
          [yaxis]="cardTypeChartOptions.yaxis"
        ></apx-chart>
      </div>
    </div>
  </div>
</div>
