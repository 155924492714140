<nz-modal
  [nzVisible]="open"
  nzTitle="SMS Confirmation"
  (nzOnCancel)="onClose()">
  <ng-container *nzModalContent>
    <div class="mb-2">
      Please enter or confirm the phone number you'd like to send this receipt to:
    </div>
    <form nz-form nzLayout="vertical" [formGroup]="form">
      <div class="flex flex-col lg:flex-row lg:items-center">
        <nz-form-control>
          <nz-input-group nzAddOnBefore="+1">
            <input
              class="w-full"
              id="phoneNumber"
              phoneNumberFormatter
              formControlName="phoneNumber"
              inputmode="numeric"
              type="tel"
              nz-input
              placeholder="Enter Phone Number"
              mask="(000) 000-0000" />
          </nz-input-group>
        </nz-form-control>
      </div>
    </form>
  </ng-container>
  <ng-container *nzModalFooter>
    <div class="flex justify-end">
      <button-default class="mr-2" (click)="onClose()">Cancel</button-default>
      <button [disabled]="!form.valid" nz-button nzType="primary" (click)="onSend()">Send</button>
    </div>
  </ng-container>
</nz-modal>

<progress-modal
  [open]="isProgressModalOpened"
  title="Receipt"
  subtitle="Sending Receipt..."
>
</progress-modal>
