import {
  Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy, Input,
} from '@angular/core';
import {
  debounceTime, distinctUntilChanged, Subject, Subscription,
} from 'rxjs';

@Component({
  selector: 'search-filter',
  templateUrl: './search-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterComponent implements OnInit, OnDestroy {
  @Input()
    placeholder: string;

  @Output() searchChange = new EventEmitter<string>();

  searchSubject = new Subject<string | undefined>();

  searchSubscription?: Subscription;

  debounceTime = 500;

  constructor() { }

  ngOnInit(): void {
    this.searchSubscription = this.searchSubject
      .pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
      )
      .subscribe((searchQuery) => {
        this.searchChange.emit(searchQuery);
      });
  }

  ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
  }

  onSearchQueryInput(event: Event): void {
    const searchQuery = (event.target as HTMLInputElement).value;
    this.searchSubject.next(searchQuery?.trim());
  }
}
