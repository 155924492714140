import { TransactionExport } from "@models";
import { BatchExport } from "@models/batch-export";
import * as dayjs from "dayjs";

export const ExportTransactionsColumns = [
            'Reference Number',
            'Internal ID',
            'Card Holder',
            'Last 4 digits',
            'Card Network',
            'Subtotal',
            'Total',
            'Surcharge',
            'Status',
            'Date',
            'Timestamp',
            'Type',
            'Authorization Code',
            'Invoice Number',
            'Amount Funded',
            'Funding Date'
          ];
class ExportUtilities {
  static removeRows(value:TransactionExport | BatchExport){
    const keysToBeRemoved=['entryMethod','source']
              keysToBeRemoved.forEach((key)=>{
            if(value.hasOwnProperty(key)){
                delete (value as any)[key];
            }
          })
  }
   static formatRows(object: any) {
    const FORMAT_VALUE_PREFIX = 'APPLYFORMAT|';
      const keys = Object.keys(object);
      for (const key of keys) {
        const value = object[key];
        if (typeof value === 'string') {
          if (value.startsWith(FORMAT_VALUE_PREFIX)) {
            object[key] = this.applyValueFormat(value);
          }
        }
      }
  }
  static applyValueFormat(value: string) {
    const [, type, formattingValue] = value.split('|');
    switch (type) {
      case 'DATE':
        return dayjs(formattingValue).format('MM/DD/YYYY');
      case 'TIME':
        return dayjs(formattingValue).format('HH:mm:ss');
      default:
        return formattingValue;
    }
  }
}
export default ExportUtilities;