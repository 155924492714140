<div class="mb-8">
    <div class="flex justify-between items-center">
        <div class="text-lg font-semibold text-section-title">{{name}}</div>
        <div class="flex items-center" *ngIf="enableEditToggle">
            <div class="ant-btn" (click)="onEditToggle()">
              <icon *ngIf="!edit" [name]="'pencil'" class="mr-1" iconClass="w-4"></icon>
              <span>{{edit ? 'Cancel' : 'Edit' }}</span>
            </div>
            <button [disabled]="!enableSave" *ngIf="edit" nz-button nzType="primary" class="h-auto ml-4" (click)="onSave()">Save changes</button>
        </div>
    </div>
    <hr class="mt-2 mb-4" />
    <ng-content></ng-content>
</div>
