import { NgModule } from '@angular/core';
import { 
  CentsAsDollarsPipe, DateFormatPipe, DateTimeFormatPipe, SurchargeAsPercentPipe, TimeFormatPipe, TransactionAmountDisplayPipe 
} from '@pipes';

const pipes = [
  CentsAsDollarsPipe,
  DateFormatPipe,
  TimeFormatPipe,
  DateTimeFormatPipe,
  TransactionAmountDisplayPipe,
  SurchargeAsPercentPipe,
]

@NgModule({
  declarations: pipes,
  exports: pipes,
  providers: pipes
})
export class PipesModule { }
