import {
  Component, OnInit, ChangeDetectionStrategy, Input,
} from '@angular/core';

@Component({
  selector: 'metadata-item',
  templateUrl: './metadata-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetadataItemComponent implements OnInit {
  @Input() label?: string;

  @Input() value?: string | null;

  @Input() alignLeft = false;

  constructor() { }

  ngOnInit(): void {
  }
}
