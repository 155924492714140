import { Injectable } from '@angular/core';
import { TransactionSource, PaymentPlanFormStep } from '@enums';
import { formatDollarsToCents } from '@helpers/utils';
import { PaymentPlanPatient, PaymentPlanConfiguration, PaymentPlanPaymentCreation } from '@models';
import { BehaviorSubject, Observable } from 'rxjs';

import { PaymentPlanService } from './payment-plan.service';
import CreatePaymentPlanRequest from './requests/create-payment-plan.request';

@Injectable({ providedIn: 'root' })
export class PaymentPlanFormService {
  private step$ = new BehaviorSubject(PaymentPlanFormStep.PlanDescription);

  step: Observable<PaymentPlanFormStep> = this.step$.asObservable();

  configuration: PaymentPlanConfiguration | undefined;

  patient: PaymentPlanPatient | undefined;

  payment: PaymentPlanPaymentCreation | undefined;

  constructor(private paymentPlanService: PaymentPlanService) { }

  savePlanConfiguration(configuration: PaymentPlanConfiguration) {
    this.configuration = configuration;
  }

  savePatient(patient: PaymentPlanPatient) {
    this.patient = patient;
  }

  savePayment(payment: PaymentPlanPaymentCreation) {
    this.payment = payment;
  }

  setStep(step: PaymentPlanFormStep) {
    this.step$.next(step);
  }

  resetForm() {
    this.configuration = undefined;
    this.patient = undefined;
    this.payment = undefined;
  }

  setCurrentStep() {
    if (this.payment) {
      this.setStep(PaymentPlanFormStep.PaymentInformation);
      return;
    }

    if (this.patient) {
      this.setStep(PaymentPlanFormStep.PaymentInformation);
      return;
    }

    if (this.configuration) {
      this.setStep(PaymentPlanFormStep.PatientInformation);
      return;
    }

    this.setStep(PaymentPlanFormStep.PlanDescription);
  }

  createPaymentPlan() {
    const { card } = this.payment!;

    const request: CreatePaymentPlanRequest = {
      configuration: {
        ...this.configuration!,
        amount: formatDollarsToCents(this.configuration!.amount),
        downPayment: formatDollarsToCents(this.configuration!.downPayment),
        maxRetries: this.payment!.maxRetries,
        invoiceNumber: this.payment!.invoiceNumber,
        installments: this.configuration!.installments!
          .filter((installment) => installment.amount !== undefined)
          .map((installment) => formatDollarsToCents(installment.amount!)),
      },
      patient: {
        ...this.patient!,
      },
      payment: {
        source: card.present ? TransactionSource.Terminal : TransactionSource.Portal,
        account: card.account,
        trackData: card.trackData,
        cardHolder: card.holder,
        cvv: card.cvv,
        expiry: `${card?.expireYear as string}${card?.expireMonth as string}`,
        zipCode: card.zipCode,
        terminal: this.payment!.terminal,
      },
    };

    return this.paymentPlanService.createPaymentPlan(request);
  }
}
