import { NgModule } from '@angular/core';
import { FooterModule } from '@payconnect/footer'


@NgModule({
  exports: [
    FooterModule
  ]
})
export class PayconnectUiModule { }
