import { Permissions } from '@enums';
import { User } from '@models';

class PermissionUtilities {
  static hasPermission(...requiredPermissions: Permissions[]) {
    const { permissions } = JSON.parse(localStorage.getItem('user')!) as User;

    return requiredPermissions.some((permission) => permissions.includes(permission));
  }

  static isGhostMode() {
    const { isGhostMode } = JSON.parse(localStorage.getItem('user')!) as User;

    return isGhostMode;
  }
}

export default PermissionUtilities;
