import {
  Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input,
} from '@angular/core';
import { TransactionStatus } from '@enums';

@Component({
  selector: 'transactions-filter-status',
  templateUrl: './filter-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsStatusFilterComponent implements OnInit {
  @Output() change = new EventEmitter<TransactionStatus[]>();

  @Input() defaultValues: TransactionStatus[] = [];

  currentOptions: TransactionStatus[] = [];

  selectedOptions: TransactionStatus[] = [];

  dropdownOpen = false;

  options = [
    TransactionStatus.Processed,
    TransactionStatus.Authorized,
    TransactionStatus.Error,
    TransactionStatus.Voided,
    TransactionStatus.Refunded,
    TransactionStatus.PartiallyRefunded,
    TransactionStatus.Declined,
  ];

  constructor() { }

  ngOnInit(): void {
    if (this.defaultValues?.length) {
      this.currentOptions = [...this.defaultValues.filter(item=>this.options.includes(item))];
      this.onApplyFilter();
    }
  }

  onCheckboxChange(values: TransactionStatus[]) {
    this.currentOptions = values;
  }

  onApplyFilter() {
    this.dropdownOpen = false;

    this.selectedOptions = [...this.currentOptions];

    this.change.emit(this.currentOptions);
  }

  removeOption(option: TransactionStatus) {
    this.currentOptions = this.currentOptions.filter((status) => status !== option);

    this.onApplyFilter();
  }
}
