import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isLocalStorageAvailable } from '@helpers/utils';
import { LoginResponse } from '@models/login-response';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResetPasswordRequest, User } from '../models';
import { BaseService } from './base.service.ts';
import { MerchantService } from './merchant.service';
import UserBasicResponse from './responses/user-basic.response';

@Injectable({ providedIn: 'root' })
export class AccountService extends BaseService {
  private userSubject: BehaviorSubject<User>;

  public user: Observable<User>;

  constructor(
    http: HttpClient,
    private router: Router,
    private merchantService: MerchantService,
  ) {
    super(http);
    let user;
    if(isLocalStorageAvailable()){
      user = localStorage.getItem('user'); 
      this.setTimeZone()
    }
    this.userSubject = new BehaviorSubject<User>(user ? JSON.parse(user) : null);
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  setTimeZone() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    localStorage.setItem('timeZone', timeZone);
  }

  getToken() {
    if(isLocalStorageAvailable()){
      return localStorage.getItem('token');
    }else{
      return null;
    }
  }

  setUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
    this.merchantService.setInitialMerchant(user.id, user.merchantIds, user.defaultUserMerchantId);
    this.userSubject.next(user);
  }

  login(username: string, password: string) {
    return this.post<LoginResponse>('/auth/signIn', { username, password }).pipe(
      map((response) => {
        const { user, token } = response;

        this.setToken(token);
        this.setUser(user);

        return user;
      }),
    );
  }

  logout(redirect = true) {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    // @ts-ignore
    this.userSubject.next(null);

    if (redirect) {
      this.router.navigate(['/account/login']);
    }
  }

  getUserByToken(token: string) {
    return this.get<UserBasicResponse>(`/users/token/${token}`);
  }

  activateUser(token: string, password: string) {
    return this.post(`/users/token/${token}`, { password });
  }

  forgotPassword(username: string) {
    return this.post('/users/password/forgot', { username });
  }

  resetPassword(body:ResetPasswordRequest) {
    return this.post('/users/password/reset', body);
  }

  setCurrentUser(merchantId: number) {
    return this.get<User>('/auth/me').pipe(
      map((user) => {
        this.merchantService.setCurrentMerchant(user.id, merchantId);
        this.setUser(user);
      }),
    );
  }
}
