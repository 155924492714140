<nz-modal
    [nzVisible]="open"
    nzTitle="Refund"
    (nzOnCancel)="onClose()">
    <ng-container *nzModalContent>
        <div class="mb-2">Please select a refund amount below:</div>
        <nz-radio-group class="flex flex-col pl-4" [(ngModel)]="type" (ngModelChange)="onTypeChange()">
            <label nz-radio nzValue="full">Entire available amount</label>
            <label nz-radio nzValue="custom">
                <div>Custom amount</div>
                <input 
                    *ngIf="type === 'custom'"
                    class="mt-2 w-32"
                    nz-input 
                    [(ngModel)]="selectedAmount"
                    placeholder="Enter Amount" 
                    inputmode="numeric"
                    currencyMask
                    [options]="{allowNegative: false}"
                    />
                <div *ngIf="error" class="mt-1 text-xs text-error">{{error}}</div>
            </label>
        </nz-radio-group>
    </ng-container>
    <ng-container *nzModalFooter>
        <div class="flex justify-end">
            <button-default class="mr-2" (click)="onClose()">Cancel</button-default>
            <button nz-button nzType="primary" (click)="onConfirm()" [nzLoading]="loading">Confirm</button>
        </div>
    </ng-container>
</nz-modal>