import {
  Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy, OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidators } from '@helpers/utils';
import { TransactionReceiptType } from '@models/send-transaction-receipt-request-params';
import { TransactionService } from '@services';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';

@Component({
  selector: 'email-receipt-modal',
  templateUrl: './email-receipt-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailReceiptModalComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter();

  @Input() open = false;

  @Input() transactionId: number;

  sendReceipt$: Subscription | undefined;

  form: FormGroup;

  isProgressModalOpened = false;

  constructor(private formBuilder: FormBuilder, private transactionService: TransactionService,
    private notificationService: NzNotificationService) { }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.pattern(FormValidators.Email)]],
    });
  }

  ngOnDestroy(): void {
    if (this.sendReceipt$) {
      this.sendReceipt$.unsubscribe();
    }
  }

  onClose() {
    this.close.emit();
  }

  onSend() {
    if (!this.form.valid) {
      return;
    }

    const { email } = this.form.value;

    this.isProgressModalOpened = true;
    this.sendReceipt$ = this.transactionService
      .sendReceipt(this.transactionId, {
        type: TransactionReceiptType.EMAIL,
        email,
      })
      .subscribe({
        next: () => {
          this.isProgressModalOpened = false;
          this.notificationService.success('Receipt', 'Receipt sent successfully by email');
          this.close.emit();
        },
        error: () => {
          this.isProgressModalOpened = false;
          this.notificationService.error('Receipt', 'There was an error sending the receipt by email');
          this.close.emit();
        },
      });
  }
}
