import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from './base.service.ts';
import { FeatureFlags } from '@models/feature-flag.js';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService extends BaseService {
  private featureFlagSubject = new BehaviorSubject<FeatureFlags>({});
  readonly featureFlags = this.featureFlagSubject.asObservable();

  constructor(http: HttpClient) {
    super(http);
  }

  fetchFeatureFlags() {
    try {
      this.get<FeatureFlags>(`/flags/portal`).subscribe((flags) =>
        this.featureFlagSubject.next(flags)
      );
    } catch (error) {
      throw error;
    }
  }
}
