import {
  Component, OnInit, ChangeDetectionStrategy, Input,
} from '@angular/core';

@Component({
  selector: 'detail-big-field[name][value]',
  templateUrl: './detail-big-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailBigFieldComponent implements OnInit {
  @Input() name: string;

  @Input() value: string | null;

  constructor() { }

  ngOnInit(): void {
  }
}
