import { BatchStatus } from '@enums';
import { StatusColor } from '@models';

class BatchUtilities {
  private static statusBackgroundMap: Record<BatchStatus, string> = {
    [BatchStatus.Open]: 'bg-warning',
    [BatchStatus.Settled]: 'bg-success',
    [BatchStatus.Error]: 'bg-error',
  };

  private static statusColorMap: Record<BatchStatus, StatusColor> = {
    [BatchStatus.Open]: 'warning',
    [BatchStatus.Settled]: 'success',
    [BatchStatus.Error]: 'error',
  };

  static getStatusBackgroundClass(status: BatchStatus) {
    return this.statusBackgroundMap[status];
  }

  static getStatusColorClass(status: BatchStatus) {
    return this.statusColorMap[status];
  }
}

export default BatchUtilities;
