import { Permissions } from '@enums';
import { MenuItem } from '@models';

const menuItems: MenuItem[] = [
  {
    icon: 'home',
    name: 'Dashboard',
    link: '',
    permissions: Permissions.DASHBOARD_VIEW,
  },
  {
    icon: 'credit-card',
    name: 'Virtual Terminal',
    link: 'virtual-terminal',
    permissions: Permissions.VIRTUAL_TERMINAL_CREATE,
  },
  {
    icon: 'currency-dollar',
    name: 'Transactions',
    link: 'transactions',
    permissions: Permissions.TRANSACTIONS_VIEW,
  },
  {
    icon: 'refresh',
    name: 'Payment Plans',
    link: 'payment-plans',
    permissions: Permissions.PAYMENT_PLANS_VIEW,
  },
  {
    icon: 'device-mobile',
    name: 'Text-to-Pay',
    link: 'text2pay',
    configEnableKey: 'text2PayEnabled',
  },
  {
    icon: 'clipboard-check',
    name: 'Batches',
    link: 'batches',
    permissions: Permissions.BATCHES_VIEW,
  },
  {
    icon: 'presentation-chart-bar',
    name: 'Reporting',
    link: 'reporting',
    permissions: Permissions.BATCHES_VIEW,
  },
  {
    icon: 'cog',
    name: 'Settings',
    link: 'settings',
    permissions: [Permissions.USERS_CREATE, Permissions.USERS_MANAGE, Permissions.DEVICES_VIEW, Permissions.DEVICES_MANAGE],

  },
  {
    icon: 'user-circle',
    name: 'Profile',
    link: 'profile',
    permissions: Permissions.PROFILE_VIEW,
  },
  {
    icon: 'question-mark-circle',
    name: 'Help',
    link: 'help',
  },
];

export default menuItems;
