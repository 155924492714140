<div 
class="rounded-tl-lg bg-remote-assistance-card z-50 p-4 group overflow-hidden transition-all duration-200 flex items-center justify-center cursor-pointer"
(click)="toggleModal(true)"
*ngIf="showHelpVideo"
>
    <div class="title flex justify-center items-center">
        <icon name="question-mark-circle" iconClass="w-7"></icon>
        <span class="ml-1 hidden group-hover:inline-block text-lg"> Help</span>
    </div>
</div>

    <nz-modal
    [nzVisible]="openVideoAssistance"
    [nzTitle]="title"
    nzCentered
    nzClosable="true"
    nzMaskClosable="false"
    (nzOnCancel)="toggleModal(false)"
    [nzStyle]="{width:'50rem',height:'20rem'}"
    >
    <ng-container *nzModalContent>
        <div class="w-full h-full flex justify-center items-center">
            <iframe class='sproutvideo-player' [src]='embedUrl' class="w-[45rem] h-[20rem]" allowfullscreen referrerpolicy='no-referrer-when-downgrade' title='Video Player'></iframe>
        </div>
    </ng-container>
    <ng-container *nzModalFooter>
    </ng-container>
  </nz-modal>

