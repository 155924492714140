import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) { }

  transform(value: string,referenceDate?:string) {
    if (value === undefined) {
      return '-';
    }
    
    let timeZone;
    if(referenceDate){
      const offset=dayjs(referenceDate).utcOffset();
      const offsetHours=Math.abs(Math.trunc(offset/60));
      const offsetMinutes=Math.abs(offset%60);
      timeZone= `UTC ${offset>0 ? '+':'-'}${offsetHours}${offsetMinutes?':'+String(offsetMinutes):''}`;
    }

    return this.datePipe.transform(value, 'MM/dd/YYYY',timeZone) ?? '';
  }
}
