import {
  Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input,
} from '@angular/core';
import { TransactionSource } from '@enums';

type TransactionSourceOption = {
  label: string;
  value: TransactionSource;
  checked?: boolean;
}

@Component({
  selector: 'transactions-filter-source',
  templateUrl: './filter-source.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsSourceFilterComponent implements OnInit {
  @Output() change = new EventEmitter<TransactionSource[]>();
  @Input() defaultValues: TransactionSource[] = [];

  selectedOptions: TransactionSourceOption[] = [];

  dropdownOpen = false;

  transactionSources: TransactionSourceOption[] = [
    { label: '3rd Party Integration', value: TransactionSource.ThirdParty },
    { label: 'Portal', value: TransactionSource.Portal },
    { label: 'Terminal', value: TransactionSource.Terminal },
    { label: 'Text-to-Pay', value: TransactionSource.Text2Pay },
  ];

  constructor() { }

  ngOnInit(): void {
    if (this.defaultValues?.length) {
      this.transactionSources = this.transactionSources.map(item => {
        if (this.defaultValues.indexOf(item.value) >= 0) item.checked = true;
        return item;
      });
      this.onApplyFilter();
    }
  }

  onApplyFilter() {
    this.dropdownOpen = false;

    const selectedOptions = this.transactionSources
      .filter((x) => x.checked);

    this.selectedOptions = selectedOptions;

    const selectedValues = selectedOptions.map((x) => x.value);

    this.change.emit(selectedValues);
  }

  removeOption(option: TransactionSourceOption) {
    const index = this.transactionSources.findIndex((x) => x.value === option.value);

    this.transactionSources[index].checked = false;

    this.onApplyFilter();
  }
}
