import {
  Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy,
} from '@angular/core';
import { Merchant } from '@models';
import { AccountService, MerchantService } from '@services';
import PermissionUtilities from '@shared/utilities/permission.utilities';
import { Subscription } from 'rxjs';

@Component({
  selector: 'location-selector',
  templateUrl: './location-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationSelectorComponent implements OnInit, OnDestroy {
  getMerchants$: Subscription;

  locations: Merchant[] = [];

  selectedLocationId: number;

  isGhostMode = PermissionUtilities.isGhostMode();

  constructor(
    private merchantService: MerchantService,
    private accountService: AccountService,
    private changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.getMerchants$ = this.merchantService
      .getMerchants()
      .subscribe((merchants) => {
        this.locations = merchants;
        this.selectedLocationId = this.merchantService.currentMerchantId;
        this.changeDetector.detectChanges();

        const currentMerchant = merchants.find((merchant) => merchant.id === this.merchantService.currentMerchantId);
        this.merchantService.currentMerchantName$.next(currentMerchant!.name);
      });
  }

  ngOnDestroy(): void {
    this.getMerchants$.unsubscribe();
  }

  onLocationChange(merchantId: number) {
    const userId = this.accountService.userValue.id;
    this.merchantService.changeMerchant(userId, merchantId);
  }
}
