<div nz-dropdown [nzDropdownMenu]="dropdown" nzTrigger="click" [(nzVisible)]="dropdownOpen" class="h-10 rounded border flex items-center justify-between px-3 py-2 text-label cursor-pointer select-none">
    <span class="whitespace-nowrap overflow-hidden overflow-ellipsis">{{placeholder}}</span>
    <icon name="chevron-down" iconClass="w-4"></icon>
</div>
<nz-dropdown-menu #dropdown>
    <div class="bg-white border rounded p-4">
        <nz-radio-group class="flex flex-col" [(ngModel)]="type" name="type">
            <label nz-radio nzValue="custom">Custom Amount</label>
            <div class="my-2 ml-5">
                <nz-input-group [nzPrefix]="inputPrefix">
                    <input
                        nz-input
                        [(ngModel)]="amount"
                        (ngModelChange)="onCustomAmountChange()"
                        placeholder="Enter Amount"
                        inputmode="numeric"
                        mask="separator.2"
                        thousandSeparator="" />
                </nz-input-group>
            </div>

              <ng-template #inputPrefix>
                  <span>$</span>
              </ng-template>

            <label nz-radio nzValue="range">Custom Amount Range</label>

            <div class="flex rounded border hover:border-primary my-2 ml-5">
                <nz-input-group class="w-36 border-transparent hover:border-transparent" [nzPrefix]="inputPrefix">
                    <input
                        nz-input
                        [(ngModel)]="amountFrom"
                        (ngModelChange)="onAmountRangeChange()"
                        (blur)="onAmountRangeBlur()"
                        placeholder="Enter Amount"
                        inputmode="numeric"
                        mask="separator.2"
                        thousandSeparator="" />
                </nz-input-group>
                <nz-input-group class="border-transparent w-auto flex items-center px-2">
                    <icon name="arrow-narrow-right"></icon>
                </nz-input-group>
                <nz-input-group class="w-36 border-transparent" [nzPrefix]="inputPrefix">
                    <input
                        nz-input
                        [(ngModel)]="amountTo"
                        (ngModelChange)="onAmountRangeChange()"
                        (blur)="onAmountRangeBlur()"
                        placeholder="Enter Amount"
                        inputmode="numeric"
                        mask="separator.2"
                        thousandSeparator="" />
                </nz-input-group>
            </div>


        </nz-radio-group>

        <div class="flex gap-2 mt-3">
            <button-default class="flex-1" (click)="dropdownOpen = false">Cancel</button-default>
            <button nz-button nzType="primary" type="submit" class="flex-1" (click)="onApplyFilter()">Apply Filter</button>
        </div>
    </div>
</nz-dropdown-menu>
<div class="mt-2" *ngIf="currentValue">
    <div class="flex items-start justify-between bg-light py-1 px-2 text-xs">
        {{currentValue}}
        <icon name="x" class="cursor-pointer" iconClass="w-4" (click)="removeFilter()"></icon>
    </div>
</div>
