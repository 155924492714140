import {
  Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input,
} from '@angular/core';

@Component({
  selector: 'amount-filter',
  templateUrl: './amount-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmountFilterComponent implements OnInit {
  @Input()
    placeholder: string;
  @Input()
    defaultAmount: number;
    @Input()
    defaultAmountRange: number[];

  @Output() change = new EventEmitter<number | number[]>();

  dropdownOpen = false;

  type: 'custom' | 'range' = 'custom';

  amount?: number;

  amountFrom?: number;

  amountTo?: number;

  currentValue?: string;

  range: number[];

  constructor() { }

  ngOnInit(): void {
    if(this.defaultAmount){
      this.type='custom';
      this.amount=this.defaultAmount;
      this.onApplyFilter();
    }
    if(this.defaultAmountRange){
      this.type='range';
      this.amountFrom=this.defaultAmountRange[0];
      this.amountTo=this.defaultAmountRange[1];
      this.onApplyFilter();
    }
  }

  onCustomAmountChange() {
    this.type = 'custom';
  }

  onAmountRangeChange() {
    this.type = 'range';
  }

  onAmountRangeBlur() {
    if (this.amountTo! < this.amountFrom!) {
      this.amountTo = this.amountFrom;
    }
  }

  removeFilter() {
    this.amount = undefined;
    this.amountFrom = undefined;
    this.amountTo = undefined;

    this.onApplyFilter();
  }

  onApplyFilter() {
    this.currentValue = undefined;

    switch (this.type) {
      default:
      case 'custom':
        this.change.emit(this.amount);

        if (this.amount !== undefined) {
          this.currentValue = `$${this.amount}`;
        }
        break;

      case 'range':
        if (this.amountFrom !== undefined && this.amountTo !== undefined) {
          this.change.emit([this.amountFrom, this.amountTo]);
          this.currentValue = `$${this.amountFrom} - $${this.amountTo}`;
        } else {
          this.change.emit(undefined);
        }
        break;
    }

    this.dropdownOpen = false;
  }
}
