import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, pairwise, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterService implements OnDestroy {
  private previousUrl: string;
  private currentUrl: string;
  subscriptions:Subscription[]=[];
  constructor(
    private router: Router,
    private location:Location
  ) { 
    this.currentUrl = this.router.url;
    this.subscriptions.push(router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    }));
  }

  addFilterToQueryParam(activatedRoute:ActivatedRoute,queryParams:any){    
    this.router.navigate(['.'], {
      relativeTo:activatedRoute,
      queryParams,
      queryParamsHandling: "merge",
      preserveFragment: true 
    });
  }

  navigateWithFilterParam(route:string){
    if(this.previousUrl && this.previousUrl.includes(route)){
      this.location.back();
    }else{
      this.router.navigate([route]);
    }
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach(item=>item.unsubscribe());
  }
}
