import {
  Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input,
} from '@angular/core';
import { TransactionAction } from '@enums';

type TransactionTypeOption = {
  label: string;
  value: TransactionAction;
  checked?: boolean;
}

@Component({
  selector: 'transactions-filter-action',
  templateUrl: './filter-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsActionFilterComponent implements OnInit {
  @Output() change = new EventEmitter<TransactionAction[]>();

  @Input() defaultValues: TransactionAction[] = [];

  selectedOptions: TransactionTypeOption[] = [];

  dropdownOpen = false;

  transactionTypes: TransactionTypeOption[] = [
    { label: 'Authorize ONLY', value: TransactionAction.AuthorizeOnly },
    { label: 'Sale', value: TransactionAction.Sale },
    { label: 'Refund', value: TransactionAction.Refund },
  ];

  constructor() { }

  ngOnInit(): void {
    if (this.defaultValues?.length) {
      this.transactionTypes = this.transactionTypes.map(item => {
        if (this.defaultValues.indexOf(item.value) >= 0) item.checked = true;
        return item;
      });
      this.onApplyFilter();
    }
  }

  onApplyFilter() {
    this.dropdownOpen = false;

    const selectedOptions = this.transactionTypes
      .filter((x) => x.checked);

    this.selectedOptions = selectedOptions;

    const selectedValues = selectedOptions.map((x) => x.value);

    this.change.emit(selectedValues);
  }

  removeOption(option: TransactionTypeOption) {
    const index = this.transactionTypes.findIndex((x) => x.value === option.value);

    this.transactionTypes[index].checked = false;

    this.onApplyFilter();
  }
}
