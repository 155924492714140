import { PaymentPlanInstallmentStatus, PaymentPlanRecurrence } from '@enums';
import { PaymentPlanStatus } from '@enums/payment-plan-status';
import { StatusColor } from '@models';

class PaymentPlanUtilities {
  private static statusColorMap: Record<PaymentPlanStatus, StatusColor> = {
    [PaymentPlanStatus.Active]: 'success',
    [PaymentPlanStatus.Cancelled]: 'error',
    [PaymentPlanStatus.Complete]: 'info',
    [PaymentPlanStatus.Pending]: 'warning',
  };

  private static statusBackgroundMap: Record<PaymentPlanStatus, string> = {
    [PaymentPlanStatus.Active]: 'bg-success',
    [PaymentPlanStatus.Cancelled]: 'bg-error',
    [PaymentPlanStatus.Complete]: 'bg-info',
    [PaymentPlanStatus.Pending]: 'bg-warning',
  };

  private static installmentStatusBackgroundMap: Record<PaymentPlanInstallmentStatus, string> = {
    [PaymentPlanInstallmentStatus.Processed]: 'bg-success',
    [PaymentPlanInstallmentStatus.Failed]: 'bg-error',
    [PaymentPlanInstallmentStatus.Pending]: 'bg-info',
    [PaymentPlanInstallmentStatus.Cancelled]: 'bg-yellow-500',
  };

  static getStatusBackgroundClass(status: PaymentPlanStatus) {
    return this.statusBackgroundMap[status];
  }

  static getStatusColorClass(status: PaymentPlanStatus) {
    return this.statusColorMap[status];
  }

  static getInstallmentStatusBackgroundClass(status: PaymentPlanInstallmentStatus) {
    return this.installmentStatusBackgroundMap[status];
  }

  static getRecurrenceNoun(recurrence: PaymentPlanRecurrence) {
    switch (recurrence) {
      case PaymentPlanRecurrence.Daily:
        return 'Day';
      case PaymentPlanRecurrence.Weekly:
        return 'Week';
      case PaymentPlanRecurrence.Biweekly:
        return 'Period';
      case PaymentPlanRecurrence.Monthly:
        return 'Month';
      case PaymentPlanRecurrence.Annually:
        return 'Year';
      default:
        return '';
    }
  }
}

export default PaymentPlanUtilities;
