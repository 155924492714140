import {
  Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy,
} from '@angular/core';
import { formatCentsToDollars, formatDollarsToCents } from '@helpers/utils';
import { ResponseError } from '@models';
import { TransactionService } from '@services';
import { Subscription } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'transaction-detail-refund-modal',
  templateUrl: './transaction-detail-refund-modal.component.html',
})
export class TransactionDetailRefundModalComponent implements OnInit, OnChanges, OnDestroy {
  @Output() close = new EventEmitter();

  @Input() open = false;

  @Input() transactionId: number;

  @Input() amount: number;

  refundTransaction$: Subscription | undefined;

  type: 'full' | 'custom' = 'full';

  selectedAmount: number;

  error: string | undefined;

  loading = false;

  constructor(private transactionService: TransactionService,private notificationService: NzNotificationService) { }

  ngOnInit(): void {
    this.selectedAmount = formatCentsToDollars(this.amount);
  }

  ngOnChanges(): void {
    this.type = 'full';
    this.error = undefined;
  }

  ngOnDestroy(): void {
    if (this.refundTransaction$) {
      this.refundTransaction$.unsubscribe();
    }
  }

  onTypeChange() {
    this.selectedAmount = formatCentsToDollars(this.amount);
  }

  onClose() {
    this.close.emit();
  }

  onConfirm() {
    this.error = undefined;
    const amount = this.type==='custom'? formatDollarsToCents(this.selectedAmount): undefined;
    if(amount!==undefined && amount<=0){
      this.error = 'Amount should be greater than zero';
      return;
    }
    else if (amount!==undefined && amount > this.amount) {
      this.error = 'Amount exceeds available amount';
      return;
    }

    this.loading = true;

    this.refundTransaction$ = this.transactionService
      .refundTransaction(this.transactionId, amount)
      .subscribe({
        next: () => {
          this.loading = false;
          this.close.emit();
        },
        error: (response) => {
          this.loading = false;
                    const error = response?.error?.error as ResponseError;
                    this.error = error.message;
                    this.notificationService.error("Error",this.error)
                    this.close.emit();
        },
      });
  }
}
