import { Text2PayMessageStatus, Text2PayStatus } from '@enums';
import { StatusColor } from '@models';

class Text2PayUtilities {
  private static statusColorMap: Record<Text2PayStatus, StatusColor> = {
    [Text2PayStatus.Paid]: 'success',
    [Text2PayStatus.Unpaid]: 'default',
    [Text2PayStatus.PartiallyPaid]: 'primary',
    [Text2PayStatus.Cancelled]: 'error',
  };

  private static statusBackgroundMap: Record<Text2PayStatus, string> = {
    [Text2PayStatus.Paid]: 'bg-success',
    [Text2PayStatus.Unpaid]: 'bg-default',
    [Text2PayStatus.PartiallyPaid]: 'bg-primary',
    [Text2PayStatus.Cancelled]: 'bg-error',
  };

  private static messageStatusColorMap: Record<Text2PayMessageStatus, StatusColor> = {
    [Text2PayMessageStatus.Sent]: 'success',
    [Text2PayMessageStatus.Queued]: 'warning',
    [Text2PayMessageStatus.Failed]: 'error',
    [Text2PayMessageStatus.Delivered]: 'success',
    [Text2PayMessageStatus.Undelivered]: 'default',
  };

  private static messageStatusBackgroundMap: Record<Text2PayMessageStatus, string> = {
    [Text2PayMessageStatus.Sent]: 'bg-success',
    [Text2PayMessageStatus.Queued]: 'bg-warning',
    [Text2PayMessageStatus.Failed]: 'bg-error',
    [Text2PayMessageStatus.Delivered]: 'bg-success',
    [Text2PayMessageStatus.Undelivered]: 'bg-default',
  };

  static getStatusBackgroundClass(status: Text2PayStatus) {
    return this.statusBackgroundMap[status];
  }

  static getStatusColorClass(status: Text2PayStatus) {
    return this.statusColorMap[status];
  }

  static getMessageStatusBackgroundClass(status: Text2PayMessageStatus) {
    return this.messageStatusBackgroundMap[status];
  }

  static getMessageStatusColorClass(status: Text2PayMessageStatus) {
    return this.messageStatusColorMap[status];
  }
}

export default Text2PayUtilities;
