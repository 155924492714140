<div #template >
 <div *ngIf="filterContent && hasValues(filterContent)" style="float: right;">
  <div style="font-weight: bold;">Filters</div>
  <div *ngFor="let key of getObjectKeys(filterContent)">
    <ng-container *ngIf="filterContent[key]">
    {{ key }} : {{ filterContent[key] }}
    </ng-container>
  </div>
</div>
<div>
  <img alt="PayConnect Logo" style="width: 10rem;margin: 10px 0px;">
</div>
  <hr style="margin:20px 0px">
  <div *ngIf="detailExportContent && detailExportColumns">
    <table style="border-collapse: collapse">
      <thead>
        <tr>
          <th style="border: 1px solid #dddddd" *ngFor="let column of detailExportColumns">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td *ngFor="let key of getObjectValues(detailExportContent)" style="
              border: 1px solid #dddddd;
              text-align: center;
              font-size: small;
            ">
            {{ key }}
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="batchSummaryDetail" style="padding: 30px 0px">
     <table style="border-collapse: collapse" >
      <thead>
        <tr style="border: 1px solid #dddddd">
          <th>Card Type</th>
          <th>Sale Count</th>
          <th>Sale Amount</th>
          <th *ngIf="batchSummaryDetail.isSubtotalAvailable">Sale Surcharge</th>
          <th>Refund Count</th>
          <th>Refund Amount</th>
          <th *ngIf="batchSummaryDetail.isSubtotalAvailable">Refund Surcharge</th>
          <th *ngIf="batchSummaryDetail.isSubtotalAvailable">Subtotal</th>
          <th *ngIf="batchSummaryDetail.isSubtotalAvailable">Surcharge</th>
          <th>Total Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of batchSummaryDetail.cardDetails" style="border: 1px solid #dddddd">
          <td style="border: 1px solid #dddddd;">{{ item.cardType }}</td>
          <td style="
              border: 1px solid #dddddd;">
            {{ item[TransactionAction.Sale].transactionCount }}
          </td>
          <td style="
              border: 1px solid #dddddd;">
            {{ item[TransactionAction.Sale].amount | currency }}
          </td>
          <td style="
            border: 1px solid #dddddd;" *ngIf="batchSummaryDetail.isSubtotalAvailable">
            {{item[TransactionAction.Sale].surcharge | currency}}
          </td>
          <td style="
              border: 1px solid #dddddd;">
            {{ item[TransactionAction.Refund].transactionCount }}
          </td>
          <td style="
              border: 1px solid #dddddd;">
            {{ item[TransactionAction.Refund].amount | currency }}
          </td>
          <td style="
            border: 1px solid #dddddd;" *ngIf="batchSummaryDetail.isSubtotalAvailable">
            {{item[TransactionAction.Refund].surcharge | currency}}
          </td>
          <td style="
            border: 1px solid #dddddd;" *ngIf="batchSummaryDetail.isSubtotalAvailable">
            {{item.subtotal | currency}}
          </td>
          <td style="
                border: 1px solid #dddddd;" *ngIf="batchSummaryDetail.isSubtotalAvailable">
              {{item.surcharge | currency}}
          </td>
          <td style="
              border: 1px solid #dddddd;">{{ item.total | currency }}</td>
        </tr>
      </tbody>
      <tfoot style="border: 1px solid #dddddd ; font-weight:bold">
        <tr>
          <td style="
              border: 1px solid #dddddd;">Total</td>
          <td style="
              border: 1px solid #dddddd;">
            {{ batchSummaryDetail[TransactionAction.Sale].totalTransaction }}
          </td>
          <td style="
              border: 1px solid #dddddd;">
            {{ batchSummaryDetail[TransactionAction.Sale].totalAmount | currency }}
          </td>
          <td style="
            border: 1px solid #dddddd;" *ngIf="batchSummaryDetail.isSubtotalAvailable">
            {{batchSummaryDetail[TransactionAction.Sale].surcharge | currency}}
          </td>
          <td style="
              border: 1px solid #dddddd;">
            {{ batchSummaryDetail[TransactionAction.Refund].totalTransaction }}
          </td>
          <td style="
              border: 1px solid #dddddd;">
            {{ batchSummaryDetail[TransactionAction.Refund].totalAmount | currency }}
          </td>
          <td style="
          border: 1px solid #dddddd;" *ngIf="batchSummaryDetail.isSubtotalAvailable">{{batchSummaryDetail[TransactionAction.Refund].surcharge | currency}}</td>
          <td style="
          border: 1px solid #dddddd;" *ngIf="batchSummaryDetail.isSubtotalAvailable">{{batchSummaryDetail.subtotal | currency}}</td>
          <td style="
          border: 1px solid #dddddd;" *ngIf="batchSummaryDetail.isSubtotalAvailable">{{batchSummaryDetail.surcharge | currency}}</td>
          <td style="
              border: 1px solid #dddddd;">{{ batchSummaryDetail.total | currency }}</td>
        </tr>
      </tfoot>
    </table> 
    </div>
  </div>
  <table style="border-collapse: collapse">
    <thead>
      <tr>
        <th style="border: 1px solid #dddddd" *ngFor="let column of exportColumns">
          {{ column }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of exportContent">
        <ng-container *ngFor="let key of getObjectValues(item)">
          <td style="
              border: 1px solid #dddddd;
              text-align: center;
              font-size: small;
            ">
            {{ key }}
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>