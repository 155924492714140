<nz-modal
    [nzVisible]="open"
    [nzTitle]="title"
    [nzClosable]="false"
    >
    <ng-container *nzModalContent>
      <div class="flex flex-col items-center">
        <nz-spinner></nz-spinner>
        <span class="text-lg">{{subtitle}}</span>
        <span class="text-sm text-default">{{description}}</span>
      </div>
    </ng-container>

  <ng-container *nzModalFooter>
  </ng-container>
</nz-modal>
