import { UserStatus } from '@enums';
import { StatusColor } from '@models';

class UserUtilities {
  private static statusBackgroundMap: Record<UserStatus, string> = {
    [UserStatus.Pending]: 'bg-warning',
    [UserStatus.Active]: 'bg-success',
    [UserStatus.Disabled]: 'bg-error',
  };

  private static statusColorMap: Record<UserStatus, StatusColor> = {
    [UserStatus.Pending]: 'warning',
    [UserStatus.Active]: 'success',
    [UserStatus.Disabled]: 'error',
  };

  static getStatusBackgroundClass(status: UserStatus) {
    return this.statusBackgroundMap[status];
  }

  static getStatusColorClass(status: UserStatus) {
    return this.statusColorMap[status];
  }
}

export default UserUtilities;
