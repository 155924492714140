export enum TransactionReceiptType {
  SMS = 'sms',
  EMAIL = 'email'
}

export interface SendTransactionReceiptRequestParams {
  type: TransactionReceiptType;
  phoneNumber?: string;
  email?: string;
  timezone?: string;
}
