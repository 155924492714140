import {Injectable} from '@angular/core';
import {TransactionAction} from '@enums';
import {createHttpQueryParams} from '@helpers/utils';
import {PaginatedResponse, Transaction, TransactionExport, TransactionState, TransactionToken,} from '@models';
import { TransactionsSummary } from '@models/batch-summary';
import {CreateTransactionRequestParams} from '@models/create-transaction-request-params';
import {CreateTransactionTokenRequestParams} from '@models/create-transaction-token-request-params';
import {SendTransactionReceiptRequestParams} from '@models/send-transaction-receipt-request-params';

import {BaseService} from './base.service.ts';
import {TransactionTableQueryParams} from './queries/transaction-table-query-params';
import TransactionDetailsResponse from './responses/transaction-details.response';
import TransactionLogResponse from './responses/transaction-log.response';
import UserBasicResponse from './responses/user-basic.response.js';
import AddTransactionSignatureRequest from './requests/add-transaction-signature.request.js';

@Injectable({providedIn: 'root'})
export class TransactionService extends BaseService {
  listTransactions(query: TransactionTableQueryParams) {
    const params = createHttpQueryParams(query);

    return this.get<PaginatedResponse<Transaction>>('/transactions', params);
  }

  getExportTransactions(query: TransactionTableQueryParams) {
    const params = createHttpQueryParams(query);

    return this.get<TransactionExport[]>('/exports/transactions', params);
  }

  getTransaction(id: number) {
    return this.get<TransactionDetailsResponse>(`/transactions/${id}`);
  }

  addSignature(req:AddTransactionSignatureRequest) {
    return this.put<AddTransactionSignatureRequest>(`/transactions/signature`,req);
  }

  findTransactionByReference(retref: string) {
    return this.get<Transaction | null>(`/transactions/references/${retref}`);
  }

  getTransactionState(id: number) {
    return this.get<TransactionState>(`/transactions/${id}/state`);
  }

  getTransactionLogs(id: number, sort: 'asc' | 'desc') {
    return this.get<TransactionLogResponse[]>(`/transactions/${id}/logs?sort=${sort}`);
  }

  createToken(params: CreateTransactionTokenRequestParams) {
    return this.post<TransactionToken>('/transactions/token', params);
  }

  createTransaction(params: CreateTransactionRequestParams) {
    if (params.action === TransactionAction.Refund) {
      return this.post<Transaction>('/transactions/refunds', params);
    }

    return this.post<Transaction>('/transactions', params);
  }

  refundTransaction(id: number, amount?: number) {
    return this.post(`/transactions/${id}/refunds`, {amount});
  }

  voidTransaction(id: number) {
    return this.post(`/transactions/${id}/voids`);
  }

  sendReceipt(id: number, params: SendTransactionReceiptRequestParams) {
    const timeZone: string = localStorage.getItem('timeZone')!;
    return this.post(`/transactions/${id}/receipt`, {
      ...params,
      timezone: timeZone
    });
  }

  getReceipt(id: number,type:string) {
    const timeZone: string = localStorage.getItem('timeZone')!;
    return this.getBlob(`/transactions/${id}/receipt?timezone=${timeZone}&type=${type}`);
  }

  getTransactionsSummary(dateRange?: string[],merchantIds?:number[]) {
    const params = createHttpQueryParams({ dateRange,merchantIds });
    return this.get<TransactionsSummary>('/transactions/summary', params);
  }

  sendRefundwithoutRetrefToken(username: string){
    return this.post('/refundwithoutretref/generatetoken', { username });
  }
  validateRefundwithoutRetrefToken(token: string){
    return this.get<UserBasicResponse>(`/refundwithoutretref/token/${token}`)
  }
}
