<div>
    <ng-container *ngTemplateOutlet="template"></ng-container>
</div>

<nz-modal
    [nzVisible]="isIdle"
    nzTitle="Your session is about to expire due to inactivity"
    [nzClosable]="false"
    >
    <ng-container *nzModalContent>
      <div class="flex flex-col items-center">
        <p class="text-xl">Logging out in <span [ngClass]="{'text-red-600':countdown<11}">{{countdown}} Sec(s)</span></p>
      </div>
    </ng-container>

  <ng-container *nzModalFooter>
  </ng-container>
</nz-modal>
