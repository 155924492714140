<nz-modal
  [nzVisible]="open"
  nzTitle="Email Confirmation"
  (nzOnCancel)="onClose()">
  <ng-container *nzModalContent>
    <div class="mb-2">
      Please enter or confirm the email address you'd like to send this receipt to:
    </div>
    <form nz-form nzLayout="vertical" [formGroup]="form">
      <div class="flex flex-col lg:flex-row lg:items-center">
        <nz-form-control>
          <input
            class="w-full"
            id="email"
            formControlName="email"
            type="email"
            nz-input
            [ngClass]="{ 'is-invalid': form.dirty && f.email.errors }"
            placeholder="Enter Email Address" />
        </nz-form-control>
      </div>
    </form>
  </ng-container>
  <ng-container *nzModalFooter>
    <div class="flex justify-end">
      <button-default class="mr-2" (click)="onClose()">Cancel</button-default>
      <button [disabled]="!form.valid" nz-button nzType="primary" (click)="onSend()">Send</button>
    </div>
  </ng-container>
</nz-modal>

<progress-modal
  [open]="isProgressModalOpened"
  title="Receipt"
  subtitle="Sending Receipt..."
>
</progress-modal>
