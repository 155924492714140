import { Pipe, PipeTransform } from '@angular/core';
import { TransactionAction, TransactionStatus } from '@enums';
import { Transaction } from '@models';

import { CentsAsDollarsPipe } from './cents-as-dollars.pipe';

@Pipe({ name: 'transactionAmountDisplay' })
export class TransactionAmountDisplayPipe implements PipeTransform {
  constructor(private centsAsDollarsPipe: CentsAsDollarsPipe) { }

  transform(transaction: Transaction) {
    if (transaction === undefined) {
      return '';
    }

    let value = transaction.amountCaptured;

    if (transaction.action === TransactionAction.AuthorizeOnly) {
      value = transaction.amountAuthorized;
    }

    if (transaction.status === TransactionStatus.Voided) {
      value = transaction.amountVoided!;
    }

    return this.centsAsDollarsPipe.transform(value);
  }
}
