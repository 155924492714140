/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output,
} from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'session-timeout-tracker',
  templateUrl: './session-timeout-tracker.component.html',
})
export class SessionTimeoutTrackerComponent implements OnInit, OnDestroy {
  @Output() timedOutSignal = new EventEmitter<boolean>();

  @Input() template:any;

  isIdle = false;

  countdown:any = null;

  subscriptions: Subscription[] = [];

  constructor(private idle: Idle, cd: ChangeDetectorRef) {
    idle.setIdle(environment.userInactivityTimeInMinutes * 60); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(environment.userTimeoutDelayInMinutes * 60); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // Runs when the user becomes idle
    this.subscriptions.push(idle.onIdleStart.subscribe(() => {
      this.isIdle = true;
    }));
    // Runs when the user is no longer idle
    this.subscriptions.push(idle.onIdleEnd.subscribe(() => {
      this.isIdle = false;
      this.countdown = null;
      cd.detectChanges();
    }));
    // Runs when the user has timed out
    this.subscriptions.push(idle.onTimeout.subscribe(() => this.timedOutSignal.emit(true)));
    this.subscriptions.push(idle.onTimeoutWarning.subscribe((seconds) => {
      this.countdown = seconds;
    }));
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    this.idle.watch();
    this.isIdle = false;
    this.countdown = null;
  }

  ngOnInit(): void {
    // right when the component initializes, start reset state and start watching
    this.reset();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe());
  }
}
