import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransactionService } from '@services/transaction.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { map, catchError, throwError } from 'rxjs';

import { BaseService } from './base.service.ts';

@Injectable({ providedIn: 'root' })
export class PrintPdfService extends BaseService {
  constructor(override http: HttpClient, private transactionService: TransactionService,
    private notificationService: NzNotificationService) {
    super(http);
  }

  printTransactionReceipt(id: number,type:string) {
    return this.transactionService.getReceipt(id,type).pipe(map((response) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      const iframe: HTMLIFrameElement = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
       iframe.onload = () => {
        if(this.isSafariBrowser()) {
          setTimeout(() => {
            iframe.contentWindow?.print();
          }, 500);
        } else {
            iframe.contentWindow?.print();
        }        
      };
      document.body.appendChild(iframe);
    }), catchError((err) => {
      this.notificationService.error('Receipt', 'There was an error loading the receipt');
      return throwError(() => err as Error);
    }));
  }

  isSafariBrowser() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return false;
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return false;
      case agent.indexOf('safari') > -1:
        return true;
      default:
        return false;
    }
  }
}

