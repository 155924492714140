<div nz-dropdown [nzDropdownMenu]="dropdown" nzTrigger="click" [(nzVisible)]="dropdownOpen" class="h-10 rounded border flex items-center justify-between px-3 py-2 text-label cursor-pointer select-none">
    <span class="whitespace-nowrap overflow-hidden overflow-ellipsis">{{placeholder}}</span>
    <icon name="chevron-down" iconClass="w-4"></icon>
</div>
<nz-dropdown-menu #dropdown>
    <div class="bg-white border rounded p-4">
        <nz-radio-group class="flex flex-col" [(ngModel)]="type" name="type">
            <label nz-radio [nzValue]="DatePickerType.TODAY" *ngIf="hideTypes.indexOf(DatePickerType.TODAY)===-1" class="my-2">Today</label>
            <label nz-radio [nzValue]="DatePickerType.WEEK" *ngIf="hideTypes.indexOf(DatePickerType.WEEK)===-1" class="my-2">This Week</label>
            <label nz-radio [nzValue]="DatePickerType.MONTH" *ngIf="hideTypes.indexOf(DatePickerType.MONTH)===-1" class="my-2">This Month</label>
            <label nz-radio [nzValue]="DatePickerType.PREVIOUS_MONTH" *ngIf="hideTypes.indexOf(DatePickerType.PREVIOUS_MONTH)===-1" class="my-2">Previous Month</label>
            <ng-container *ngIf="hideTypes.indexOf(DatePickerType.DATE)===-1">
                <label nz-radio [nzValue]="DatePickerType.DATE" class="my-2">Custom Date</label>
                <nz-date-picker nzFormat="MM/dd/YYYY" nzPlaceHolder="Select a Date" class="my-2 ml-5" [(ngModel)]="date" (ngModelChange)="onCustomDateChange()" name="date" ></nz-date-picker>
            </ng-container>
            <ng-container *ngIf="hideTypes.indexOf(DatePickerType.RANGE)===-1">
                <label nz-radio [nzValue]="DatePickerType.RANGE" class="my-2">Custom Date Range</label>
                <nz-range-picker nzFormat="MM/dd/YYYY" class="mb-2 ml-5" name="range" [(ngModel)]="range" (ngModelChange)="onRangeChange()"></nz-range-picker>
            </ng-container>
        </nz-radio-group>

        <div class="flex gap-2 mt-3">
            <button-default class="flex-1" (click)="dropdownOpen = false">Cancel</button-default>
            <button nz-button nzType="primary" type="submit" class="flex-1" (click)="onApplyFilter()">Apply Filter</button>
        </div>
    </div>
</nz-dropdown-menu>
<div class="mt-2" *ngIf="currentValue">
    <div class="flex items-start justify-between bg-light py-1 px-2 text-xs">
        {{currentValue}}
        <icon name="x" class="cursor-pointer" iconClass="w-4" (click)="removeFilter()"></icon>
    </div>
</div>
