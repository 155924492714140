export enum ExportWorkerAction {
  BeginExport,
}

interface ExportWorkerMessageBody {
  action: ExportWorkerAction,
  rows?: any[],
  columns?: string[],
}

interface ExportWorkerMessage {
  data: ExportWorkerMessageBody,
}

export default ExportWorkerMessage;
