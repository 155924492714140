import {
  Directive, Input, OnInit, TemplateRef, ViewContainerRef,
} from '@angular/core';
import { Permissions } from '@enums';
import { AccountService } from '@services';

@Directive({ selector: '[ifPermission]' })
export class RequiredPermissionDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private accountService: AccountService,
    private viewContainer: ViewContainerRef,
  ) { }

  permissions: Permissions[];

  @Input('ifPermission')
  set requiredPermission(permissions: Permissions | Permissions[] | undefined) {
    if (!permissions) {
      return;
    }

    if (!Array.isArray(permissions)) {
      permissions = [permissions];
    }

    if (!permissions || !permissions.length) {
      throw new Error('There must be at least one required permission.');
    }

    this.permissions = permissions;
  }

  ngOnInit() {
    if (this.permissions === undefined) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    const { permissions } = this.accountService.userValue;

    const hasAccess = this.permissions.some((permission) => permissions.includes(permission));

    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
