<div class="bg-light py-4 px-4 rounded flex-col h-full">
    <div class="font-bold">Summary</div>
    <table class="mt-3 w-full">
        <thead class="sticky top-0 bg-light">
            <tr class="text-help-label">
                <th class="font-light pr-5 text-left"  *ngIf="processedSummary.cardDetails.length>0 && processedSummary.cardDetails[0].merchantName">Location</th>
                <th class="font-light pr-5 text-left">Card Type</th>
                <th 
                    class="font-light pr-4 text-left"
                    [ngClass]="{'hover:underline hover:cursor-pointer':emitValue}"
                    (click)="emitValue && sendValue({act:TransactionAction.Sale})"
                >Sale Count</th>
                <th 
                    class="font-light pr-5 text-left" 
                    [ngClass]="{'hover:underline hover:cursor-pointer':emitValue}"
                    (click)="emitValue && sendValue({act:TransactionAction.Sale})"
                >Sale Amount</th>
                <th class="font-light pr-5 text-left" *ngIf="processedSummary.isSubtotalAvailable">Sale Surcharge</th>
                <th class="font-light pr-4 text-left" 
                [ngClass]="{'hover:underline hover:cursor-pointer':emitValue}"
                (click)="emitValue && sendValue({act:TransactionAction.Refund})"
                >Refund Count</th>
                <th class="font-light pr-5 text-left"
                [ngClass]="{'hover:underline hover:cursor-pointer':emitValue}"
                (click)="emitValue && sendValue({act:TransactionAction.Refund})"
                >Refund Amount</th>
                <th class="font-light pr-5 text-left" *ngIf="processedSummary.isSubtotalAvailable">Refund Surcharge</th>
                <th class="font-light pr-5 text-left" *ngIf="processedSummary.isSubtotalAvailable">Subtotal</th>
                <th class="font-light pr-5 text-left" *ngIf="processedSummary.isSubtotalAvailable">Surcharge</th>
                <th class="font-light text-left">Total Amount</th>
            </tr>
        </thead>
        <tbody>
            <tr class="text-center" *ngFor="let item of processedSummary.cardDetails">
                <td class="pt-2 text-left" *ngIf="item.merchantName">{{item.merchantName}}</td>
                <td class="pt-2 text-left">{{item.cardType}}</td>
                <td class="pt-2 text-left">{{item[TransactionAction.Sale].transactionCount}}</td>
                <td class="pt-2 text-left">{{item[TransactionAction.Sale].amount | currency}}</td>
                <td class="pt-2 text-left" *ngIf="processedSummary.isSubtotalAvailable">{{item[TransactionAction.Sale].surcharge | currency}}</td>
                <td class="pt-2 text-left">{{item[TransactionAction.Refund].transactionCount}}</td>
                <td class="pt-2 text-left">{{item[TransactionAction.Refund].amount | currency}}</td>
                <td class="pt-2 text-left" *ngIf="processedSummary.isSubtotalAvailable">{{item[TransactionAction.Refund].surcharge | currency}}</td>
                <td class="pt-2 text-left" *ngIf="processedSummary.isSubtotalAvailable">{{item.subtotal | currency}}</td>
                <td class="pt-2 text-left" *ngIf="processedSummary.isSubtotalAvailable">{{item.surcharge | currency}}</td>
                <td class="pt-2 text-left">{{item.total | currency}}</td>
            </tr>
        </tbody>
        <tfoot class="sticky bottom-0 bg-light">
            <tr class="text-center text-primary font-medium border-t border-gray-300">
                <td class="pt-2 text-left"  *ngIf="processedSummary.cardDetails.length>0 && processedSummary.cardDetails[0].merchantName"></td>
                <td class="pt-2 text-left">Total</td>
                <td class="pt-2 text-left">{{processedSummary[TransactionAction.Sale].totalTransaction}}</td>
                <td class="pt-2 text-left">{{processedSummary[TransactionAction.Sale].totalAmount | currency}}</td>
                <td class="pt-2 text-left" *ngIf="processedSummary.isSubtotalAvailable">{{processedSummary[TransactionAction.Sale].surcharge | currency}}</td>
                <td class="pt-2 text-left">{{processedSummary[TransactionAction.Refund].totalTransaction}}</td>
                <td class="pt-2 text-left">{{processedSummary[TransactionAction.Refund].totalAmount | currency}}</td>
                <td class="pt-2 text-left" *ngIf="processedSummary.isSubtotalAvailable">{{processedSummary[TransactionAction.Refund].surcharge | currency}}</td>
                <td class="pt-2 text-left" *ngIf="processedSummary.isSubtotalAvailable">{{processedSummary.subtotal | currency}}</td>
                <td class="pt-2 text-left" *ngIf="processedSummary.isSubtotalAvailable">{{processedSummary.surcharge | currency}}</td>
                <td class="pt-2 text-left">{{processedSummary.total | currency}}</td>
            </tr>
        </tfoot>
    </table>
</div>