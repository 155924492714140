import {
  Component, OnInit, ChangeDetectionStrategy, Input,
} from '@angular/core';
import { BatchStatus } from '@enums';
import BatchUtilities from '@shared/utilities/batch.utilities';

@Component({
  selector: 'batch-status[status]',
  templateUrl: './batch-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchStatusComponent implements OnInit {
  @Input() status!: BatchStatus;

  constructor() { }

  ngOnInit(): void {
  }

  get backgroundClass(): string {
    return BatchUtilities.getStatusBackgroundClass(this.status);
  }
}
