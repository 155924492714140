import {
  HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MerchantService } from '@services';
import { Observable } from 'rxjs';

@Injectable()
export class MerchantInterceptor implements HttpInterceptor {
  constructor(private merchantService: MerchantService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.merchantService.currentMerchantId) {
      request = request.clone({
        setHeaders: {
          'x-merchant': this.merchantService.currentMerchantId.toString(),
        },
      });
    }

    return next.handle(request);
  }
}
