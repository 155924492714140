import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Merchant, MerchantDetails, MerchantReceiptTemplate } from '@models';
import { BehaviorSubject, Observable } from 'rxjs';

import { BaseService } from './base.service.ts';
import MerchantConfigResponse from './responses/merchant-config.response';
import { PatchMerchantRequest } from '@models/patch-merchant.request.js';
import PermissionUtilities from '@shared/utilities/permission.utilities';

@Injectable({ providedIn: 'root' })
export class MerchantService extends BaseService {
  private config$: BehaviorSubject<MerchantConfigResponse>;

  public config: Observable<MerchantConfigResponse>;

  public get configValue(): MerchantConfigResponse {
    return this.config$.value;
  }

  currentMerchantId: number;

  currentMerchantName$ = new BehaviorSubject<string>('');

  merchantKey = 'merchantId';

  private initialMerchantConfig: MerchantConfigResponse = {
    text2PayEnabled: false,
    requireZipCode: false,
    refundWithoutRetrefAllowed: false,
    refundWithoutRetrefDeactivated: false,
    terminalTransactionAllowed: false,
    isSurchargeEnabled: false,
    signatureCaptureAllowed:false,
    paymentPlanNotificationEnabled: {
      canText: {
        textReminder:false,
      },
      canEmail: {
        emailReminder:false,
        approvedEmail:false,
        declinedEmail:false,
      }
    }
  };

  constructor(http: HttpClient) {
    super(http);

    this.config$ = new BehaviorSubject<MerchantConfigResponse>(this.initialMerchantConfig);
    this.config = this.config$.asObservable();
  }

  initializeMerchant(userId: number) {
    const merchantId = this.getCurrentMerchant(userId);

    if (merchantId) {
      this.setCurrentMerchant(userId, +merchantId);
    }
  }

  setCurrentMerchant(userId: number, merchantId: number) {
    const userMerchantMap = JSON.parse(localStorage.getItem(this.merchantKey) ?? '{}');

    userMerchantMap[userId] = merchantId;

    localStorage.setItem(this.merchantKey, JSON.stringify(userMerchantMap));
    this.currentMerchantId = merchantId;

    this.getMerchantConfig().subscribe((config) => this.config$.next(config));
      }

  changeMerchant(userId: number, merchantId: number) {
    this.setCurrentMerchant(userId, merchantId);
    window.location.reload();
  }

  getCurrentMerchant(userId: number) {
    const userMerchantMap = JSON.parse(localStorage.getItem(this.merchantKey) ?? '{}');

    return userMerchantMap[userId] as number | undefined;
  }

  setInitialMerchant(userId: number, userMerchantIds: number[], defaultUserMerchantId: number) {
    const isGhostMode = PermissionUtilities.isGhostMode();
    if(isGhostMode) {
      this.setCurrentMerchant(userId, this.currentMerchantId);
    } else if(defaultUserMerchantId) {
      this.setCurrentMerchant(userId, defaultUserMerchantId);
    } else {
      const [merchantId] = userMerchantIds;
      this.setCurrentMerchant(userId, merchantId);
    }
  }

  getMerchants() {
    return this.get<Merchant[]>('/merchants');
  }

  getMerchantConfig() {
    return this.get<MerchantConfigResponse>(`/merchants/${this.currentMerchantId}/config`);
  }

  getMerchantReceiptTemplate() {
    return this.get<MerchantReceiptTemplate>(`/merchants/${this.currentMerchantId}/receipt-template`)
  }

  updateMerchantReceiptTemplate(body: MerchantReceiptTemplate) {
    return this.put<MerchantReceiptTemplate>(`/merchants/${this.currentMerchantId}/receipt-template`, body);
  }

  getMerchantById(id: number) {
    return this.get<MerchantDetails>(`/merchants/${id}`)
  }

  patchMerchant(id:number,body:PatchMerchantRequest){
    return this.patch<MerchantDetails>(`/merchants/${id}`,body);
  }
}
