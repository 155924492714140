import {
  Component, OnInit, ChangeDetectionStrategy, Input,
} from '@angular/core';
import { HeroIconName } from 'ng-heroicon';

@Component({
  selector: 'icon[name]',
  templateUrl: './icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
  @Input() name: HeroIconName;

  @Input() iconClass: string = 'align-middle';

  constructor() { }

  ngOnInit(): void {
  }
}
