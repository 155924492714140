import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'surchargeAsPercent' })
export class SurchargeAsPercentPipe implements PipeTransform {
  constructor() { }

  transform(value?: number) {
    if (value === undefined) {
      return '';
    }

    return `${value / 100}%`;
  }
}
