<div class="mb-8 max-h-screen overflow-y-auto">
  <div class="flex items-center">
    <div class="text-lg font-semibold text-section-title mr-10">Transaction History</div>
    <div class="flex flex-col cursor-pointer" (click)="onSortToggle(asc)">
      <icon [name]="'chevron-up'" class="mr-2" iconClass="w-5"></icon>
      <icon [name]="'chevron-down'" class="mr-2" iconClass="w-5"></icon>
    </div>
  </div>

  <div class="flex flex-col">
    <transaction-log-list-item *ngFor="let log of getTransactionLogs$ | async" [log]="log"></transaction-log-list-item>
  </div>

</div>
