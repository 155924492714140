<div class="block bg-white rounded-lg border">
  <div class="text-2sm font-bold px-4 py-2 border-b">{{title}}</div>
  <div class="flex flex-col w-full p-4">
    <div class="flex justify-between items-center mb-2">
      <span>Sales</span>
      <span class="font-bold">{{report.sales | centsAsDollars}}</span>
    </div>
    <div class="flex justify-between items-center mb-2">
      <span>Authorization</span>
      <span class="font-bold">{{report.authorizations | centsAsDollars}}</span>
    </div>
    <div class="flex justify-between items-center mb-2">
      <span>Refunds</span>
      <span class="font-bold">{{report.refunds | centsAsDollars}}</span>
    </div>
    <div class="flex justify-between items-center border-t pt-2">
      <span>Net</span>
      <span class="font-bold text-3xl text-info">{{report.net | centsAsDollars}}</span>
    </div>
  </div>
</div>
