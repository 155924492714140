import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HelpVideoType } from '@enums';
import { HelpVideoCatalogue } from '@models/help.interface';
import { HeaderService } from '@services';
import { HelpService } from '@services/help.service';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'help-video-assistance',
  templateUrl: './help-video-assistance.component.html'
})
export class HelpVideoAssistanceComponent implements OnDestroy {
  openVideoAssistance=false;

  title='';

  showHelpVideo=true;

  videoCatalogue:HelpVideoCatalogue[];

  embedUrl:SafeResourceUrl;

  subscriptions:Subscription[]=[];

  currentUrl:string;

  constructor(
    private headerService:HeaderService,
    private helpService:HelpService,
    private sanitizer: DomSanitizer,
  ){
    this.subscriptions.push(this.headerService.title.asObservable().subscribe((value)=>{
      this.title=`Help Video - ${value}`;
    }));
    this.subscriptions.push(this.helpService.helpVideoCatalogue$.subscribe(data=>{
      this.videoCatalogue=data;
      this.generateUrl();
    }));
    this.subscriptions.push(this.helpService.currentRoute$.subscribe((url)=>{
      this.currentUrl=url;
     this.generateUrl();
    }));
  }

  toggleModal(open:boolean){
    this.openVideoAssistance=open;
  }

  generateUrl(){
    const data=this.videoCatalogue?.find((item:any)=>this.currentUrl?.includes(item.path));
    if(data && !this.currentUrl?.includes('help')){
      this.showHelpVideo=true;
      const videoUrl=`${environment.SPROUTVIDEO_URL}/${data.type===HelpVideoType.Video?'embed':data.type}/${data.id}/${data.security}`;
      this.embedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
    }else{
      this.showHelpVideo=false;
    }
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach(item=>item.unsubscribe());
  }
}
