import { Injectable } from '@angular/core';
import { Device } from '@models';
import UpdateDeviceRequest from '@services/requests/update-device.request';

import { BaseService } from './base.service.ts';

@Injectable({ providedIn: 'root' })
export class DeviceService extends BaseService {
  getDevices() {
    return this.get<Device[]>('/devices');
  }

  getDevice(id: number) {
    return this.get<Device>(`/devices/${id}`);
  }

  createDevice(request: UpdateDeviceRequest) {
    return this.post<Device>('/devices', request);
  }

  updateDevice(id: number, request: UpdateDeviceRequest) {
    return this.put<Device>(`/devices/${id}`, request);
  }

  getTerminals() {
    return this.get<string[]>('/devices/terminals');
  }

  deleteDevice(id: number) {
    return this.delete<number>(`/devices/${id}`);
  }
}
