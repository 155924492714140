import { Injectable } from '@angular/core';
import { PaymentPlanStatus } from '@enums';
import { createHttpQueryParams } from '@helpers/utils';
import {
  PaginatedResponse, PaymentPlan, PaymentPlanList, Transaction,PaymentPlansSummary
} from '@models';
import { PaymentPlanTableQueryParams } from '@services/queries/payment-plan-table-query-params';

import { BaseService } from './base.service.ts';
import CreatePaymentPlanRequest from './requests/create-payment-plan.request';
import UpdatePaymentPlanRequest, { CreatePaymentPlanInstallmentRequest } from './requests/update-payment-plan.request';

@Injectable({ providedIn: 'root' })
export class PaymentPlanService extends BaseService {
  listPaymentPlans(query: PaymentPlanTableQueryParams) {
    const params = createHttpQueryParams(query);

    return this.get<PaginatedResponse<PaymentPlanList>>('/payment-plans', params);
  }

  createPaymentPlan(request: CreatePaymentPlanRequest) {
    return this.post<PaymentPlan>('/payment-plans', request);
  }

  getPaymentPlan(id: number) {
    return this.get<PaymentPlan>(`/payment-plans/${id}`);
  }

  getPaymentPlansSummary() {
    return this.get<PaymentPlansSummary[]>(`/payment-plans/summary`);
  }

  getTransactionsForPaymentPlan(paymentPlanId: number, sort: 'asc' | 'desc') {
    return this.get<Transaction[]>(`/payment-plans/${paymentPlanId}/transactions?sort=${sort}`);
  }

  updatePaymentPlan(planId: number, request: UpdatePaymentPlanRequest) {
    return this.put(`/payment-plans/${planId}`, request);
  }

  cancelPaymentPlan(planId: number) {
    return this.put<PaymentPlan>(`/payment-plans/${planId}/status`, { status: PaymentPlanStatus.Cancelled });
  }

  cancelPaymentPlanInstallments(planId: number,installmentIds:number[]) {
    return this.put<PaymentPlan>(`/payment-plans/${planId}/installments/cancel`, { installmentIds });
  }

  createPaymentPlanInstallments(planId: number,installments:CreatePaymentPlanInstallmentRequest[]) {
    return this.post<PaymentPlan>(`/payment-plans/${planId}/installments/create`, { installments });
  }


}
