<div [formGroup]="form" class="w-full min-w-[425px]">
    <div class="w-full border rounded bg-card px-6 py-4">
        <div class="flex justify-between items-center h-10 mb-4" *ngIf="cardPresentToggle || !readonly">
            <div class="flex items-center gap-4" *ngIf="cardPresentToggle">
                <ng-container *ngIf="showToggle">
                    <img src="/assets/icons/keyed-in.png" class="w-10" nz-tooltip nzTooltipTitle="Keyed In"/>
                    <nz-switch class="always-active-switch" formControlName="present"></nz-switch>
                    <img src="/assets/icons/terminal.png" class="w-10" nz-tooltip nzTooltipTitle="Terminal"/>
                </ng-container>
            </div>

            <button *ngIf="!readonly && !readonlyCardAndExpiry" [disabled]="isCardPresent" type="button" nz-button nzType="primary" nzShape="round" [nzGhost]="true" (click)="onSwiperClick()">
                Swiper
            </button>
        </div>
        <div class="flex flex-col gap-y-2">
            <input nz-input autocomplete="cc-name" [readonly]="readonly" formControlName="holder" placeholder="Name on Card" class="border-transparent" />
            <div class="flex flex-col md:flex-row gap-2">
                <div class="flex-1 relative {{isIframeDisabled ? 'pointer-events-none cursor-not-allowed' : ''}}">
                    <ng-container *ngIf="!readonly && !readonlyCardAndExpiry && !isCardReaderChecked">
                        <nz-spin *ngIf="iframeLoading" class="flex justify-center items-center h-[40px]" [nzSpinning]="true" [nzSimple]="true"></nz-spin>
                        <iframe allowtransparency="true" class="w-full h-[40px] {{iframeLoading ? 'invisible h-0' : ''}}" id="tokenFrame" name="tokenFrame" [src]="iframeUrl" frameborder="0" scrolling="no"></iframe>
                    </ng-container>
                    <input 
                        *ngIf="(readonly || readonlyCardAndExpiry) && !isCardReaderChecked"
                        type="tel"
                        inputmode="numeric"
                        nz-input 
                        formControlName="account" 
                        class="border-transparent sm:tracking-cc sm:pr-16 placeholder:tracking-normal" 
                        [ngClass]="{'bg-gray-200 border border-gray-400 opacity-50':readonlyCardAndExpiry}"
                        [readonly]="true"
                        placeholder="Card Number" />
                    <input 
                        *ngIf="isCardReaderChecked"
                        type="tel"
                        inputmode="numeric"
                        nz-input 
                        formControlName="cardReaderData" 
                        class="border-transparent sm:tracking-cc sm:pr-16 placeholder:tracking-normal" 
                        [readonly]="true"
                        placeholder="Card Number" />
                </div>
                <input 
                    type="tel"
                    nz-input 
                    inputmode="numeric" 
                    autocomplete="cc-csc" 
                    ccCVC 
                    [readonly]="readonly" 
                    formControlName="cvv" 
                    placeholder="CVV" 
                    class="w-16 border-transparent"
                    minlength="3"
                    maxlength="4" />
            </div>
            <span *ngIf="cardNumberValidation" class="text-xs text-error">Card Number Required</span>
            <div class="flex justify-between">
                <div class="flex gap-2">
                    <select *ngIf="!readonly && !readonlyCardAndExpiry"  autocomplete="cc-exp-month" formControlName="expireMonth" class="w-20 ant-input border-transparent">
                        <option value="" disabled selected hidden>MM</option>
                        <option *ngFor="let month of months" [value]="month">{{month}}</option>
                    </select>
                    <select *ngIf="!readonly && !readonlyCardAndExpiry" autocomplete="cc-exp-year" formControlName="expireYear" class="w-20 ant-input border-transparent">
                        <option value="" disabled selected hidden>YYYY</option>
                        <option *ngFor="let year of years" [value]="year">{{year}}</option>
                    </select>
                    <input *ngIf="readonly || readonlyCardAndExpiry" [ngClass]="{'bg-gray-200 border border-gray-400 opacity-50':readonlyCardAndExpiry}"  nz-input autocomplete="cc-exp-month" [readonly]="true" formControlName="expireMonth" maxlength="2" placeholder="MM" class="w-16 border-transparent" />
                    <input *ngIf="readonly || readonlyCardAndExpiry" [ngClass]="{'bg-gray-200 border border-gray-400 opacity-50':readonlyCardAndExpiry}" nz-input autocomplete="cc-exp-year" [readonly]="true" formControlName="expireYear" maxlength="4" placeholder="YYYY" class="w-16 border-transparent" />
                </div>
                <input 
                    type="text"
                    nz-input 
                    [readonly]="readonly" 
                    formControlName="zipCode" 
                    placeholder="ZIP" 
                    class="w-24 border-transparent"
                    minlength="5"
                    maxlength="5" />
            </div>
        </div>
        <div *ngIf="cardBrand">
            <img style="width: 4rem;height: 4rem;" [src]="'assets/cards/'+cardBrand+'.png'" [alt]="cardBrand">
        </div>
    </div>
</div>

<nz-modal
    [nzVisible]="showCardReaderModal"
    [nzTitle]="'Card Reader Input'"
    (nzOnCancel)="onCancelCardReaderModal()"
    [nzClosable]="false"
    [nzMaskClosable]="false"
    nzCentered
    [nzOkText]="null"
>
    <ng-container *nzModalContent >
        <div class="flex flex-col items-center">
        <nz-spinner></nz-spinner>
        <span class="text-lg">Please swipe the card</span>
        <input
            class="outline-none text-transparent hover:cursor-default"
            focus
            #cardInput
            [(ngModel)]="cardReaderOutput"
            (keyup.enter)="onCardReadingComplete()"
            (blur)="onCardInputBlur($event)" />
        </div>
    </ng-container>
</nz-modal>
