import { Component, OnInit } from '@angular/core';
import { AccountService, HelpService, MerchantService } from '@services';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  template: `
  <session-timeout-tracker [template]="template" (timedOutSignal)="sessionTimedOut()"></session-timeout-tracker>
  <ng-template #template>
    <nz-layout class="min-h-[calc(100vh-40px)]">
      <nz-sider [nzWidth]="235" nzTheme="light">
        <app-sidebar></app-sidebar>
      </nz-sider>
      <nz-layout>
        <nz-header class="p-0 h-auto leading-normal z-10">
          <app-header></app-header>
        </nz-header>
        <nz-content nzFlex="auto">
          <div class="h-full p-8 relative">
            <router-outlet></router-outlet>
            <div class="help-content fixed bottom-0 right-0">
              <help-video-assistance></help-video-assistance>
            </div>
          </div>
        </nz-content>
      </nz-layout>
    </nz-layout>
  </ng-template>
  `,
})
export class MainLayoutComponent implements OnInit {
  constructor(
    private accountService: AccountService,
    private merchantService: MerchantService,
    private notificationService: NzNotificationService,
    private helpService:HelpService
  ) {
   this.getHelpVideos();
   }

   getHelpVideos(){
    this.helpService.getHelpVideos().subscribe((res)=>{
      this.helpService.setHelpVideoCatalogue(res);
    })
  }

  ngOnInit(): void {
    this.merchantService.initializeMerchant(this.accountService.userValue.id);
  }

  sessionTimedOut() {
    this.notificationService.info('Please login again', 'Logged out due to inactivity');
    this.accountService.logout();
  }
}
