import {
  Component, ChangeDetectionStrategy, EventEmitter, Output, Input,
} from '@angular/core';

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent {
  @Input() open = false;

  @Input() titleText: string;

  @Input() text: string;

  @Input() loading = false;

  @Output() close = new EventEmitter();

  @Output() confirm = new EventEmitter();

  onClose() {
    this.close.emit();
  }

  onConfirm() {
    this.confirm.emit();
  }
}
