import { CardTypes } from "../../enums";

export default class CardUtilities {
    static getCardBrand(token:string) {
        if(token){
            switch (token[1]) {
              case '3':
                return CardTypes.AMEX
              case '4':
                return CardTypes.VISA
              case '5':
                return CardTypes.MASTERCARD
              case '6':
                return CardTypes.DISCOVER
            }
        }
        return;
    }
}