import { Component, OnDestroy, OnInit } from '@angular/core';
import { FeatureFlags } from '@models/feature-flag';
import { FeatureFlagService } from '@services/feature-flag.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
})
export class BannerComponent implements OnInit, OnDestroy {
  featureFlagSubscription: Subscription;
  banners: FeatureFlags = {}

  constructor(private feature: FeatureFlagService) {}

  ngOnInit(): void {
    this.featureFlagSubscription = this.feature.featureFlags.subscribe(
      (flags) => {
        this.showBanner(flags);
      }
    );
  }

  ngOnDestroy(): void {
    this.featureFlagSubscription.unsubscribe();
  }

  showBanner(flags: FeatureFlags) {
    for (const [key, value] of Object.entries(flags)) {
      if (key.startsWith('pAYC_PLATFORM_BANNER') && value?.enabled) {
        (this.banners as any)[key] = value;
      }
    }    
  }
}
