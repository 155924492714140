export enum Permissions {
  PASSWORD_RESET = 'PASSWORD_RESET',
  DASHBOARD_VIEW = 'DASHBOARD_VIEW',
  VIRTUAL_TERMINAL_USER_PROFILE_CREATE = 'VIRTUAL_TERMINAL_USER_PROFILE_CREATE',
  VIRTUAL_TERMINAL_REFUND_WITHOUT_RETREF = 'VIRTUAL_TERMINAL_REFUND_WITHOUT_RETREF',
  VIRTUAL_TERMINAL_CREATE = 'VIRTUAL_TERMINAL_CREATE',
  TRANSACTIONS_VIEW = 'TRANSACTIONS_VIEW',
  TRANSACTIONS_VIEW_DETAILS = 'TRANSACTIONS_VIEW_DETAILS',
  TRANSACTIONS_REFUND_OR_VOID = 'TRANSACTIONS_REFUND_OR_VOID',
  TRANSACTIONS_EXPORT = 'TRANSACTIONS_EXPORT',
  BATCHES_VIEW = 'BATCHES_VIEW',
  BATCHES_VIEW_DETAILS = 'BATCHES_VIEW_DETAILS',
  BATCHES_EXPORT = 'BATCHES_EXPORT',
  BATCHES_EXPORT_SUMMARY = 'BATCHES_EXPORT_SUMMARY',
  BATCHES_FORCE_BATCH = 'BATCHES_FORCE_BATCH',
  PROFILE_VIEW = 'PROFILE_VIEW',
  USERS_CREATE = 'USERS_CREATE',
  USERS_MANAGE = 'USERS_MANAGE',
  DEVICES_VIEW = 'DEVICES_VIEW',
  DEVICES_MANAGE = 'DEVICES_MANAGE',
  PAYMENT_PLANS_VIEW = 'PAYMENT_PLANS_VIEW',
  PAYMENT_PLANS_VIEW_DETAILS = 'PAYMENT_PLANS_VIEW_DETAILS',
  PAYMENT_PLANS_CREATE = 'PAYMENT_PLANS_CREATE',
  PAYMENT_PLANS_MANAGE = 'PAYMENT_PLANS_MANAGE',
  RECEIPT_TEMPLATE_MANAGE='RECEIPT_TEMPLATE_MANAGE',
  MULTI_LOCATION_REPORTING='MULTI_LOCATION_REPORTING',
  SIGNATURE_CAPTURE_MANAGE='SIGNATURE_CAPTURE_MANAGE'
}
