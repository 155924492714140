import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  LocationSelectedInputComponent,
  ButtonDefaultComponent,
  HeaderComponent,
  IconComponent,
  SidebarComponent,
  SpinnerComponent,
  CardInformationComponent,
  LocationSelectorComponent,
  TransactionStatusComponent,
  SearchFilterComponent,
  DateFilterComponent,
  AmountFilterComponent,
  PaymentPlanStatusComponent,
  PaymentPlanInstallmentStatusComponent,
  TransactionListComponent,
  TransactionsSourceFilterComponent,
  TransactionsStatusFilterComponent,
  TransactionsActionFilterComponent,
  TransactionDetailRefundModalComponent,
  TransactionVoidModalComponent,
  BatchStatusComponent,
  DetailSectionComponent,
  DetailFieldComponent,
  DetailBigFieldComponent,
  EmailReceiptModalComponent,
  SMSReceiptModalComponent,
  ProgressModalComponent,
  ConfirmationModalComponent,
  TransactionSummaryCardComponent,
  Text2PayStatusComponent,
  Text2PayMessageStatusComponent,
  TransactionLogListComponent,
  TransactionLogListItemComponent,
  UserStatusComponent,
  BannerComponent,
  HelpVideoAssistanceComponent,
  SignaturePadComponent,
  ExportTemplateComponent
} from '@components';
import { BatchSummaryComponent } from '@components/batch-summary/batch-summary.component';
import { PipesModule } from '@pipes/pipes.module';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import {
  HeroIconModule,
  home, logout, currencyDollar, users, creditCard, refresh, clipboardCheck, cog, userCircle, informationCircle, ban,support,
  dotsVertical, clipboardList, search, chevronDown, arrowNarrowRight, x, pencil, locationMarker, upload, checkCircle,questionMarkCircle,
  arrowNarrowLeft, chevronUp,chevronRight, exclamationCircle, calendar, user, trash, deviceMobile, desktopComputer, deviceTablet,
  presentationChartBar,save,plusCircle,
  mail,
  check
} from 'ng-heroicon';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';

import { SessionTimeoutTrackerComponent } from './components/session-timeout-tracker/session-timeout-tracker.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { RequiredPermissionDirective } from './directives/required-permission.directive';
import { MetadataItemComponent } from './components/metadata-item/metadata-item.component';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  CommonModule,
  CreditCardDirectivesModule,
  NgxCurrencyModule,
  NzButtonModule,
  NzLayoutModule,
  NzGridModule,
  NzMenuModule,
  NzIconModule,
  NzInputModule,
  NzAlertModule,
  NzSelectModule,
  NzFormModule,
  NzInputNumberModule,
  NzCheckboxModule,
  NzToolTipModule,
  NzAutocompleteModule,
  NzModalModule,
  NzTableModule,
  NzTagModule,
  NzDropDownModule,
  NzRadioModule,
  NzDatePickerModule,
  NzDividerModule,
  NzSpinModule,
  NzNotificationModule,
  NzSwitchModule,
  NzCollapseModule,
  PipesModule,
];

const components = [
  IconComponent,
  ButtonDefaultComponent,
  SidebarComponent,
  HeaderComponent,
  TransactionStatusComponent,
  SpinnerComponent,
  CardInformationComponent,
  LocationSelectedInputComponent,
  LocationSelectorComponent,
  PaymentPlanStatusComponent,
  PaymentPlanInstallmentStatusComponent,
  SearchFilterComponent,
  DateFilterComponent,
  AmountFilterComponent,
  TransactionListComponent,
  TransactionsSourceFilterComponent,
  TransactionsStatusFilterComponent,
  TransactionsActionFilterComponent,
  TransactionDetailRefundModalComponent,
  TransactionVoidModalComponent,
  BatchStatusComponent,
  DetailSectionComponent,
  DetailFieldComponent,
  DetailBigFieldComponent,
  EmailReceiptModalComponent,
  SMSReceiptModalComponent,
  ProgressModalComponent,
  ConfirmationModalComponent,
  TransactionSummaryCardComponent,
  SessionTimeoutTrackerComponent,
  Text2PayStatusComponent,
  Text2PayMessageStatusComponent,
  TransactionLogListComponent,
  TransactionLogListItemComponent,
  UserStatusComponent,
  BatchSummaryComponent,
  BannerComponent,
  HelpVideoAssistanceComponent,
  SignaturePadComponent,
  ExportTemplateComponent,
  MetadataItemComponent
];


const directives = [
  RequiredPermissionDirective,
  PhoneMaskDirective,
];

@NgModule({
  imports: [
    ...modules,
    NgxMaskModule.forRoot(),
    HeroIconModule.forRoot({
      home,
      logout,
      currencyDollar,
      users,
      creditCard,
      refresh,
      clipboardCheck,
      cog,
      userCircle,
      informationCircle,
      ban,
      dotsVertical,
      clipboardList,
      search,
      chevronDown,
      chevronUp,
      chevronRight,
      arrowNarrowRight,
      x,
      pencil,
      locationMarker,
      upload,
      checkCircle,
      arrowNarrowLeft,
      exclamationCircle,
      calendar,
      user,
      trash,
      deviceMobile,
      desktopComputer,
      deviceTablet,
      presentationChartBar,
      support,
      questionMarkCircle,
      save,
      mail,
      plusCircle,
      check
    }, {
      defaultHostDisplay: 'inlineBlock',
      attachDefaultDimensionsIfNoneFound: true,
    }),
  ],
  declarations: [
    ...components,
    ...directives,
  ],
  exports: [
    ...modules,
    ...components,
    ...directives,
    HeroIconModule,
    NgxMaskModule
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
    PipesModule
  ],
})
export class ThemeModule { }
