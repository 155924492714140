import {
  Component, ChangeDetectionStrategy, Input,
} from '@angular/core';
import { ProcessedTransactionsReport } from '@models/dashboard-report.response';

@Component({
  selector: 'transaction-summary-card',
  templateUrl: './transaction-summary-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionSummaryCardComponent {
  @Input() title: string;

  @Input() report: ProcessedTransactionsReport;

  constructor() { }
}
