import { Injectable } from '@angular/core';
import { createHttpQueryParams } from '@helpers/utils';
import { DashboardReportResponse, PaymentAnalyticsResponse } from '@models/dashboard-report.response';
import { PaymentAnalyticsQueryParams } from '@services/queries/payment-analytics-query-params';
import * as dayjs from 'dayjs';

import { BaseService } from './base.service.ts';

@Injectable({ providedIn: 'root' })
export class ReportService extends BaseService {
  getReports() {
    const date = dayjs().startOf('day').toISOString();
    const params = createHttpQueryParams({ date });

    return this.get<DashboardReportResponse>('/reports', params);
  }

  getPaymentAnalytics(query: PaymentAnalyticsQueryParams) {
    const params = createHttpQueryParams(query);

    return this.get<PaymentAnalyticsResponse>('/reports/payment-analytics', params);
  }
}
