import {
  Component, OnInit, ChangeDetectionStrategy, Input,
} from '@angular/core';
import { UserStatus } from '@enums';
import UserUtilities from '@shared/utilities/user.utilities';

@Component({
  selector: 'user-status[status]',
  templateUrl: './user-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserStatusComponent implements OnInit {
  @Input() status!: UserStatus;

  constructor() { }

  ngOnInit(): void {
  }

  get backgroundClass(): string {
    return UserUtilities.getStatusBackgroundClass(this.status);
  }
}
