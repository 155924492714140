import {
  Component, OnInit, ChangeDetectionStrategy, Input,
} from '@angular/core';
import { Text2PayStatus } from '@enums';
import Text2PayUtilities from '@shared/utilities/text2pay.utilities';

@Component({
  selector: 'text2pay-status[status]',
  templateUrl: './text2pay-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Text2PayStatusComponent implements OnInit {
  @Input() status!: Text2PayStatus;

  constructor() { }

  ngOnInit(): void {
  }

  get backgroundClass(): string {
    return Text2PayUtilities.getStatusBackgroundClass(this.status);
  }
}
