import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'button-default',
  templateUrl: './button-default.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonDefaultComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
