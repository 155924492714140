import {
  Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges,
} from '@angular/core';
import { TransactionService } from '@services';
import TransactionLogResponse from '@services/responses/transaction-log.response';
import { Observable } from 'rxjs';

@Component({
  selector: 'transaction-log-list',
  templateUrl: './transaction-log-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionLogListComponent implements OnInit, OnChanges {
  @Input()
    transactionId: number;

  getTransactionLogs$: Observable<TransactionLogResponse[]>;

  asc = false;

  constructor(private transactionService: TransactionService) { }

  ngOnInit(): void {
    this.getTransactionLogs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.transactionId) {
      this.getTransactionLogs();
    }
  }

  onSortToggle(asc: boolean) {
    this.asc = !asc;
    this.getTransactionLogs();
  }

  private getTransactionLogs() {
    this.getTransactionLogs$ = this.transactionService.getTransactionLogs(this.transactionId, this.asc ? 'asc' : 'desc');
  }
}
