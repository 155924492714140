import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from '@models';
import { AccountService, HeaderService, MerchantService } from '@services';
import MerchantConfigResponse from '@services/responses/merchant-config.response';
import { BaseComponent } from '@shared/directives/base.component';

import menuItems from './menu-items';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent extends BaseComponent implements OnInit {
  menuItems = menuItems;

  config: MerchantConfigResponse;

  constructor(
    private accountService: AccountService,
    private merchantService: MerchantService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.merchantService.config.subscribe((config) => this.config = config);
  }

  logout() {
    this.accountService.logout();
  }

  itemIsEnabled(item: MenuItem) {
    if (item.configEnableKey === undefined) {
      return true;
    }

    if (!this.config) {
      return false;
    }

    if (this.config[item.configEnableKey] !== undefined) {
      return this.config[item.configEnableKey];
    }

    return false;
  }
}
