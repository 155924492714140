import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { HeaderService } from '@services';
import { BaseComponent } from '@shared/directives/base.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends BaseComponent implements OnInit {
  title$: Observable<string>;

  constructor(private headerService: HeaderService) {
    super();
  }

  ngOnInit(): void {
    this.title$ = this.headerService.title.asObservable();
  }
}
