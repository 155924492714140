import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output,
} from '@angular/core';
import { TransactionAction } from '@enums';
import { formatBatchSummary } from '@helpers/utils';
import { Merchant } from '@models';
import { TransactionsSummary, BatchSummaryProcessed } from '@models/batch-summary';

@Component({
  selector: 'batch-summary',
  templateUrl: './batch-summary.component.html',
})
export class BatchSummaryComponent implements OnChanges {
  @Input() batchSummary: TransactionsSummary;
  @Input() merchants: Merchant[];

  @Input() emitValue = false;

  @Output() valueEmitter = new EventEmitter<{ [key: string]: string }>();

  TransactionAction = TransactionAction;
  processedSummary: BatchSummaryProcessed;

  ngOnChanges(): void {
    if (this.batchSummary) {
      this.processedSummary = formatBatchSummary(this.batchSummary,this.merchants);
    }
  }

  sendValue(value: { [key: string]: string }) {
    this.valueEmitter.emit(value);
  }


}
