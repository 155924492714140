<div class="flex flex-col h-full border-r py-4 px-6">
  <a routerLink="/" title="PayConnect">
    <img alt="PayConnect Logo" src="assets/logo.png" class="mb-6 mx-auto w-36"/>
  </a>
  <ul>
    <ng-container *ngFor="let item of menuItems">
      <ng-container *ngIf="itemIsEnabled(item)">
        <li *ifPermission="item.permissions"
            class="flex items-center py-2 px-4 mb-1 rounded-xl cursor-pointer hover:text-primary transition"
            [routerLink]="item.link" routerLinkActive="bg-light"
            [routerLinkActiveOptions]="{ exact: item.link === ''  }">
          <icon [name]="item.icon" class="mr-4"></icon>
          <span>{{ item.name }}</span>
        </li>
      </ng-container>
    </ng-container>

    <li class="flex items-center py-2 px-4 mb-1 rounded-xl cursor-pointer hover:text-primary transition mt-10"
        (click)="logout()">
      <icon name="logout" class="mr-4"></icon>
      <span>Log Out</span>
    </li>
  </ul>
  <div class="mt-auto">
    <strong>DentalXChange</strong>
    <div class="text-xs">
      <a href="mailto:payconnect@dentalxchange.com">payconnect@dentalxchange.com</a>
      <br>
      800-576-6412 ext. 472
    </div>
  </div>
</div>
