import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'signature-pad',
  templateUrl: './signature-pad.component.html'
})


export class SignaturePadComponent {

  @Input() formGroup:FormGroup;
  @Input() formControlName:string;
  @Input() confirmButtonText:string='Done';
  @Input() isLoading:boolean=true;
  @Output() sendSignature=new EventEmitter<string>();


  @ViewChild('sigImg', { static: false })
  cnv: ElementRef;

  cnvContext: CanvasRenderingContext2D | null;
  tmr:any;
  signature:string | null;
  isSigning=false;
  validate=false;
  version: string;

  isDeviceConnected = false;
  isDriverPresent = true;
  existingDriverVersion:string;


  constructor(private notification: NzNotificationService) {

    this.existingDriverVersion = localStorage.getItem('sigWebDriver')!

  }

  ngAfterViewInit(): void {    
    this.checkSigWebStatus()
  }

  ngOnDestroy(): void {
    this.closingSigWeb();
  }

  closingSigWeb() {
    this.signature=null;
    ClearTablet();
    SetTabletState(0, this.tmr);
  }

  onSign() {
    if(!this.isDeviceConnected) {
      this.isDeviceConnected = this.checkDeviceStatus();
      if(this.isDeviceConnected) this.onSign()
      else return
    }
    this.isSigning=true;
  
    this.closingSigWeb();
    SetDisplayXSize(350);
    SetDisplayYSize(100);
    SetJustifyMode(0);

    if (this.tmr == null) {
      this.tmr = SetTabletState(1, this.cnvContext!, 100);
    } else {
      SetTabletState(0, this.tmr);
      this.tmr = null;
      this.tmr = SetTabletState(1, this.cnvContext!, 100);
    }

  }

  onClear() {
    if(!this.isDeviceConnected) {
      this.isDeviceConnected = this.checkDeviceStatus();
      if(this.isDeviceConnected) this.onClear()
      else return
    }
    this.closingSigWeb();
    if(this.isSigning) this.onSign();
    (<HTMLCanvasElement>this.cnv.nativeElement).getContext('2d')?.clearRect(0,0,350,100);
  }

  onDone() {
    if(!this.isDeviceConnected) {
      this.isDeviceConnected = this.checkDeviceStatus();
      if(this.isDeviceConnected) this.onDone()
      else return
    }
    this.isSigning=false;
    if (NumberOfTabletPoints() == 0) {
      this.validate=true;
    } else {
      SetTabletState(0, this.tmr);

      SetSigCompressionMode(1);
      SetImageXSize(350);
      SetImageYSize(100);
      SetImagePenWidth(5);
      GetSigImageB64((base64: string) => {
        this.signature=base64;
        if(this.formGroup) this.formGroup.get(this.formControlName)?.setValue(base64);
        this.sendSignature.emit(this.signature);
      });
    }
  }

  checkForOldVersion(minVersion: string, CurrentVersion: string){
    try{
      const oldParts = minVersion.split('.')
      const newParts = CurrentVersion.split('.')
      for (let i = 0; i < newParts.length; i++) {
        const a = parseInt(newParts[i]) || 0
        const b = parseInt(oldParts[i]) || 0
        if (a < b) return true
        if (a > b) return false
      }
      return false;
    }
    catch(err){
      console.error(err);
      return true
    }


  }
  checkSigWebStatus(){
    if(IsSigWebInstalled()){
      this.version = GetSigWebVersion();
      if(this.version && (this.existingDriverVersion !== this.version)){
        localStorage.setItem('sigWebDriver', this.version)
        const isSigWeb_1_7xInstalled = !this.checkForOldVersion('1.7.0.0', this.version)
        if(isSigWeb_1_7xInstalled) {
          const daysUntilCertExpires:string = GetDaysUntilCertificateExpires();
          this.notification.info(`SigWeb ${this.version} installed`, `Certificate expires in ${daysUntilCertExpires} days`);
        }
        else {
            this.notification.warning(`SigWeb ${this.version} installed`, 
            `A newer version of SigWeb is available. Please uninstall the 
            current version and install the new version of SigWeb`);
        }
      }
      this.isDeviceConnected = this.checkDeviceStatus()
      if(this.isDeviceConnected){
          this.cnvContext = (<HTMLCanvasElement>this.cnv.nativeElement).getContext('2d');
      }
    }
    else{
      this.isDriverPresent = false;
      this.notification.error('Driver not found', 'Please make sure SigWeb is installed and running on this PC.');
    }
  }

  checkDeviceStatus(){
    SetTabletState(1);
    const deviceStatus = GetTabletState();
    if(deviceStatus !== '1'){
      this.notification.error('Device not found', 'Unable to communicate with SigWeb device');
      return false
    }
    else {
      return true
    }
  }

}
