<nz-modal
    [nzVisible]="open"
    nzTitle="Void Transaction"
    (nzOnCancel)="onClose()">
    <ng-container *nzModalContent>
        <div class="mb-2">
          Would you like to void this transaction? By processing, this transaction will be cancelled and the patient will receive funds in full within the next 24 hours.
        </div>
    </ng-container>
    <ng-container *nzModalFooter>
        <div class="flex justify-end">
            <button-default class="mr-2" (click)="onClose()">Cancel</button-default>
            <button nz-button nzType="primary" (click)="onConfirm()">Confirm</button>
        </div>
    </ng-container>
</nz-modal>
