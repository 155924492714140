<div class="mb-8">
    <search-filter placeholder="Search by reference #, name, card brand, invoice number or auth code" (searchChange)="onSearch($event)"></search-filter>

    <div class="grid md:grid-cols-2 lg:grid-cols-5 gap-3">
      <date-filter placeholder="Date" [defaultType]="defaultDateType" [defaultDates]="defaultDates" (change)="onDateFilterChange($event)"></date-filter>
      <amount-filter placeholder="Amount" [defaultAmount]="defaultAmount" [defaultAmountRange]="defaultAmountRange" (change)="onAmountFilterChange($event)"></amount-filter>
      <transactions-filter-action [defaultValues]="defaultAction" (change)="onActionFilterChange($event)"></transactions-filter-action>
      <transactions-filter-source [defaultValues]="defaultSources" (change)="onSourceFilterChange($event)"></transactions-filter-source>
      <transactions-filter-status [defaultValues]="defaultStatuses" (change)="onStatusFilterChange($event)"></transactions-filter-status>
    </div>
  </div>

  <div class="flex justify-between items-center">
    <div class="text-lg ml-2 mb-2">Transaction History</div>
    <div class="flex justify-end items-center">
    <ng-container *ifPermission="Permissions.TRANSACTIONS_EXPORT">
      <ng-container *ngIf="!(exportService.isExporting | async)">
        <button nz-button nzType="text" (click)="onExport()">
          <icon name="upload" class="mr-2" iconClass="w-5"></icon>
          Export
        </button>
      </ng-container>
      <ng-container *ngIf="exportService.isExporting | async">
        <div class="flex items-center">
          <nz-spin [nzSimple]="true" class="mr-4"></nz-spin>
          Exporting
          <button nz-button nzType="text" class="text-primary ml-2" (click)="onExportCancel()">Cancel Export</button>
        </div>
      </ng-container>
      <ng-container>
        <button nz-button nzType="text" [nzLoading]="pdfLoading" (click)="onPrint()">
          <icon name="clipboard-list" class="mr-2" iconClass="w-5" ></icon>
          Print
        </button>
       
      </ng-container>
    </ng-container>
    </div>
  </div>
  <nz-table
      [nzData]="transactions"
      [nzFrontPagination]="false"
      [nzLoading]="loading"
      [nzTotal]="total"
      [nzPageSize]="tableParams.pageSize"
      [nzPageIndex]="tableParams.page"
      nzSize="middle"
      (nzQueryParams)="onQueryParamsChange($event)"
  >
      <thead>
          <tr>
            <th nzColumnKey="retref" [nzSortFn]="true">Reference Number</th>
            <th nzColumnKey="cardHolder" [nzSortFn]="true">Name on Card</th>
            <th nzColumnKey="cardNumber" [nzSortFn]="true">Last 4 Digits</th>
            <th nzColumnKey="cardNetwork" [nzSortFn]="true">Card Type</th>
            <th nzColumnKey="amount" [nzSortFn]="true">Total</th>
            <th nzColumnKey="createdAt" [nzSortFn]="true">Date</th>
            <th nzColumnKey="action" [nzSortFn]="true">Type</th>
            <th nzColumnKey="status" [nzSortFn]="true">Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let transaction of transactions">
            <td>{{ getReferenceNumber(transaction)  }} <icon *ngIf="transaction.type === TransactionTypes.Recurring" name="refresh" iconClass="w-3" title="Recurring"></icon></td>
            <td>{{ transaction.paymentMethod.cardPaymentMethod.name }}</td>
            <td>{{ transaction.paymentMethod.cardPaymentMethod.last4Digits }}</td>
            <td>{{ transaction.paymentMethod.cardPaymentMethod.network }}</td>
            <td>{{ transaction | transactionAmountDisplay }}</td>
          <td>{{ transaction.createdAt | dateFormat }}</td>
            <td>{{ transactionActions[transaction.action] }}</td>
            <td>
              <transaction-status [status]="transaction.status"></transaction-status>
            </td>
            <td>
              <div class="flex">
                <span *ifPermission="Permissions.TRANSACTIONS_REFUND_OR_VOID">
                  <icon (click)="refundOrVoid(transaction)" nz-tooltip nzTooltipTitle="Refund or void" iconClass="w-5" class="cursor-pointer" name="ban"></icon>
                </span>
                <span class="ml-2">
                  <icon
                    *ngIf="transaction.retref"
                    nz-dropdown
                    nzTrigger="click"
                    [nzDropdownMenu]="receiptMenu"
                    nz-tooltip
                    nzTooltipTitle="View receipt options"
                    iconClass="w-5"
                    class="cursor-pointer"
                    name="clipboard-list">
                  </icon>
                  <icon
                    *ngIf="!transaction.retref"
                    nz-dropdown
                    nz-tooltip
                    nzTooltipTitle="View receipt options"
                    iconClass="w-5"
                    class="opacity-50"
                    name="clipboard-list">
                  </icon>
                  <nz-dropdown-menu #receiptMenu>
                    <ul nz-menu>
                      <li nz-menu-item (click)="onClickReceiptOption(transaction,'print','Standard')">
                        Standard Print
                      </li>
                      <li nz-menu-item (click)="onClickReceiptOption(transaction,'print','Receipt')">
                        Receipt Print
                      </li>
                      <li nz-menu-item (click)="onClickReceiptOption(transaction,'sms')">Send via. SMS</li>
                      <li nz-menu-item (click)="onClickReceiptOption(transaction,'email')">Send via. Email</li>
                    </ul>
                  </nz-dropdown-menu>
                </span>
                <span *ifPermission="Permissions.TRANSACTIONS_VIEW_DETAILS" class="ml-auto" routerLink="/transactions/{{transaction.id.toString()}}">
                  <icon nz-tooltip nzTooltipTitle="Open Detailed view" iconClass="w-5 ml-1" class="cursor-pointer" name="dots-vertical"></icon>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
  </nz-table>

  <transaction-detail-refund-modal
    *ngIf="selectedTransaction"
    [open]="refundModalOpen"
    [transactionId]="selectedTransaction.id"
    [amount]="selectedTransaction.amount - (selectedTransaction.amountRefunded ?? 0)"
    (close)="onRefundModalClose()">
  </transaction-detail-refund-modal>

  <transaction-void-modal
    *ngIf="selectedTransaction"
    [open]="voidModalOpen"
    [transactionId]="selectedTransaction.id"
    (close)="onVoidModalClose()">
  </transaction-void-modal>

  <email-receipt-modal
    *ngIf="selectedTransaction"
    [open]="sendReceiptState === sendReceiptStates.SEND_BY_EMAIL"
    [transactionId]="selectedTransaction.id"
    (close)="sendReceiptState = undefined"
  >
  </email-receipt-modal>

  <sms-receipt-modal
    *ngIf="selectedTransaction"
    [open]="sendReceiptState === sendReceiptStates.SEND_BY_SMS"
    [transactionId]="selectedTransaction.id"
    (close)="sendReceiptState = undefined"
  >
  </sms-receipt-modal>

  <progress-modal
    [open]="sendReceiptState === sendReceiptStates.PRINT"
    title="Receipt"
    subtitle="Loading Receipt..."
  >
  </progress-modal>

  <ng-template #exportModalInProgress>
    <div class="flex items-center">
      <nz-spin [nzSimple]="true"></nz-spin>
      <div class="flex items-center justify-between flex-1 ml-4 pr-4">
        <div>Export in progress</div>
        <button nz-button nzType="text" class="text-primary" (click)="onExportCancel()">Cancel Export</button>
      </div>
    </div>
  </ng-template>

  <ng-template #exportModalComplete let-blob="data">
    <div class="flex items-center">
      <icon name="check-circle" iconClass="w-8 text-success"></icon>
      <div class="flex items-center justify-between flex-1 ml-4 pr-4">
        <div>Export complete</div>
        <button nz-button nzType="text" class="text-primary" (click)="onExportDownload(blob)">Download</button>
      </div>
    </div>
  </ng-template>
<app-export-template *ngIf="!pdfLoading && exportContent" [exportContent]="exportContent" [exportColumns]="exportColumns" [filterContent]="filterContent" class="hidden"></app-export-template>