<nz-modal
    [nzVisible]="open"
    [nzTitle]="titleText"
    (nzOnCancel)="onClose()">
    <ng-container *nzModalContent>
        <div class="mb-2">
          {{text}}
        </div>
    </ng-container>
    <ng-container *nzModalFooter>
        <div class="flex justify-end">
            <button-default class="mr-2" (click)="onClose()">Cancel</button-default>
            <button [nzLoading]="loading" nz-button nzType="primary" (click)="onConfirm()">Confirm</button>
        </div>
    </ng-container>
</nz-modal>
