import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { HeaderService } from '@services';

@Component({
  templateUrl: './unauthorized.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnauthorizedComponent implements OnInit {
  constructor(private headerService: HeaderService) { }

  ngOnInit(): void {
    this.headerService.clearTitle();
  }
}
