import { Pipe, PipeTransform } from '@angular/core';

import { DateFormatPipe } from './date-format.pipe';
import { TimeFormatPipe } from './time-format.pipe';

@Pipe({
  name: 'dateTimeFormat',
})
export class DateTimeFormatPipe implements PipeTransform {
  constructor(
    private dateFormatPipe: DateFormatPipe,
    private timeFormatPipe: TimeFormatPipe,
  ) { }

  transform(value: string) {
    if (value === undefined) {
      return '';
    }

    return `${this.dateFormatPipe.transform(value)!} ${this.timeFormatPipe.transform(value)!}`;
  }
}
