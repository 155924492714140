import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { FeatureFlagService } from '@services/feature-flag.service';
import { Subscription } from 'rxjs';

import { User } from './models';
import { AccountService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  subscriptions = new Subscription()
  hideGlobalItems = true

  constructor(
    private router:Router,
    private feature: FeatureFlagService
    ) {
    this.feature.fetchFeatureFlags()
    this.subscriptions.add(this.router.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {        
        const { detachLayout=false } = event.state.root.firstChild?.data!;
        this.hideGlobalItems = detachLayout
      }
    }));
    
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

}
