<div> 
    <div class="signaturePad w-[350px] h-[100px] rounded-xl relative">
      <div class="loader" *ngIf="isLoading" class="w-[350px] h-[100px] flex justify-center items-center rounded-xl absolute top-0 left-0">
        <nz-spin nzSimple nzSize="large"></nz-spin>
      </div>
      <canvas #sigImg 
        class="border border-solid rounded-xl"  
        [ngClass]="{
            'bg-gray-100':!isSigning,
            'border-red-500':!isSigning&&validate&&!signature
          }" 
        width="350" 
        height="100">
      </canvas> 
    </div>
  
    <br />
  
    <div>
      <button class="mr-2" nz-button nzGhost  nzType="primary" [disabled]="!isDriverPresent || isLoading || isSigning" (click)="onSign()">Sign</button>
  
      <button class="mr-2" nz-button nzDanger [disabled]="!isDriverPresent || isLoading" (click)="onClear()">Clear</button>
  
      <button nz-button nzType="primary" [disabled]="!isDriverPresent || isLoading || !isSigning" (click)="onDone()">{{confirmButtonText}}</button>
    </div>
  </div>
  