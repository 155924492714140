import {
  Component, OnInit, ChangeDetectionStrategy, Input,
} from '@angular/core';
import { Text2PayMessageStatus } from '@enums';
import Text2PayUtilities from '@shared/utilities/text2pay.utilities';

@Component({
  selector: 'text2pay-message-status[status]',
  templateUrl: './text2pay-message-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Text2PayMessageStatusComponent implements OnInit {
  @Input() status!: Text2PayMessageStatus;

  constructor() { }

  ngOnInit(): void {
  }

  get backgroundClass(): string {
    return Text2PayUtilities.getMessageStatusBackgroundClass(this.status);
  }
}
