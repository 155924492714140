import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorInterceptor, JwtInterceptor } from '@helpers';
import { MerchantInterceptor } from '@helpers/merchant.interceptor';
import { NgIdleModule } from '@ng-idle/core';
import { ThemeModule } from '@shared';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { HomeComponent } from './modules/home';
import { PayconnectUiModule } from './payconnect-ui/payconnect-ui.module';
import { Router } from '@angular/router';

registerLocaleData(en);

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ThemeModule,
    NgApexchartsModule,
    NgIdleModule.forRoot(),
    PayconnectUiModule,
  ],
  declarations: [AppComponent, MainLayoutComponent, HomeComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MerchantInterceptor, multi: true },
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
