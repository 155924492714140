import {
  Component, OnInit, ChangeDetectionStrategy, Input,
} from '@angular/core';
import { PaymentPlanStatus } from '@enums/payment-plan-status';
import PaymentPlanUtilities from '@shared/utilities/payment-plan.utilities';

@Component({
  selector: 'payment-plan-status[status]',
  templateUrl: './payment-plan-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentPlanStatusComponent implements OnInit {
  @Input() status!: PaymentPlanStatus;

  constructor() { }

  ngOnInit(): void {
  }

  get backgroundClass(): string {
    return PaymentPlanUtilities.getStatusBackgroundClass(this.status);
  }
}
