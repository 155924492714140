import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nz-spinner',
  templateUrl: './spinner.component.html',
  styles: [
    `
      i {
        font-size: 50px;
      }
    `,
  ],
})
export class SpinnerComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
