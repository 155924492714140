import {
  Component, OnInit, ChangeDetectionStrategy, Input,
} from '@angular/core';
import { StatusColor } from '@models';

@Component({
  selector: 'detail-field[name][value]',
  templateUrl: './detail-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailFieldComponent implements OnInit {
  @Input() name: string;

  @Input() value?: string;

  @Input() color: StatusColor;

  constructor() { }

  ngOnInit(): void {
  }

  get colorClass() {
    switch (this.color) {
      case 'primary':
        return 'text-primary';
      case 'success':
        return 'text-success';
      case 'error':
        return 'text-error';
      case 'info':
        return 'text-info';
      case 'warning':
        return 'text-warning';
      case 'default':
        return 'text-default';
      default:
        break;
    }

    return undefined;
  }
}
