import {
  Component, ChangeDetectionStrategy, Input,
} from '@angular/core';

@Component({
  selector: 'progress-modal',
  templateUrl: './progress-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressModalComponent {
  @Input() open = false;

  @Input() title: string;

  @Input() subtitle: string;

  @Input() description: string;
}
