import {
  Component, OnInit, ChangeDetectionStrategy, Input,
} from '@angular/core';
import { TransactionStatus } from '@enums';
import TransactionUtilities from '@shared/utilities/transaction.utilities';

@Component({
  selector: 'transaction-status[status]',
  templateUrl: './transaction-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionStatusComponent implements OnInit {
  @Input() status!: TransactionStatus;

  transactionStatuses = TransactionStatus;

  constructor() { }

  ngOnInit(): void {
  }

  get backgroundClass(): string {
    return TransactionUtilities.getStatusBackgroundClass(this.status);
  }
}
