import { TransactionStatus } from '@enums';
import { StatusColor } from '@models';

class TransactionUtilities {
  private static statusColorMap: Record<TransactionStatus, StatusColor> = {
    [TransactionStatus.Processed]: 'success',
    [TransactionStatus.Error]: 'error',
    [TransactionStatus.Voided]: 'default',
    [TransactionStatus.Authorized]: 'info',
    [TransactionStatus.Refunded]: 'default',
    [TransactionStatus.PartiallyRefunded]: 'default',
    [TransactionStatus.Declined]: 'error',
  };

  private static statusBackgroundMap: Record<TransactionStatus, string> = {
    [TransactionStatus.Processed]: 'bg-success',
    [TransactionStatus.Error]: 'bg-error',
    [TransactionStatus.Voided]: 'bg-default',
    [TransactionStatus.Authorized]: 'bg-info',
    [TransactionStatus.Refunded]: 'bg-default',
    [TransactionStatus.PartiallyRefunded]: 'bg-default',
    [TransactionStatus.Declined]: 'bg-error',
  };

  static getStatusBackgroundClass(status: TransactionStatus) {
    return this.statusBackgroundMap[status];
  }

  static getStatusColorClass(status: TransactionStatus) {
    return this.statusColorMap[status];
  }
}

export default TransactionUtilities;
